import { SOCIAL_ORGANIZATIONS } from './company.model'
import { ObjKeyValueString } from './main.model'
import { ABSENCE_DETAIL } from './payslip.model'

export enum STATUS {
  IN_ORDER = 'enRegle',
  TO_COMPLETE = 'aCompleter',
  COMPLETE = 'complet',
  BEING_CORRECTED = 'aCorrigerenCours',
  TO_CORRECT = 'aCorriger',
  PENDING_PAYMENT = 'attentePaiement',
  BLOCKED = 'bloque',
  ON_GOING = 'enCours',
  ACCEPTED = 'ACCEPTEE',
  DEADLINE_EXCEEDED = 'DELAI_DEPASSE',
}

export enum STATUS_LABEL {
  IN_ORDER = 'En règle',
  TO_COMPLETE = 'À compléter',
  COMPLETE = 'Complète',
  BEING_CORRECTED = 'En cours de correction',
  TO_CORRECT = 'À corriger',
  PENDING_PAYMENT = 'En attente de paiement',
  BLOCKED = 'Bloquée',
  ON_GOING = 'En cours',
  ACCEPTED = 'Acceptée',
  DEADLINE_EXCEEDED = 'Délai dépassé',
}

export enum SENDING_STATUS {
  OK = 'ok',
  KO = 'ko',
}

export type Declaration = {
  id: number | null
  status: STATUS
  libelleStatus: string
  periode: string
  nom: string
  prenoms: string
  exigibilite: string
  nature: DECLARATION_TYPE
  dateDernierEnvoi: string | null
  exigibilteAtteinte: boolean
  libelleExigibilite: string
  modifiable: boolean
  dernierEnvoiId: number | null
  statutEnvoi: SENDING_STATUS | null
  dateLimiteRenvoi: string | null
  renvoi: boolean
}

export type DeclarationDashboard = {
  id: number | null
  status: STATUS
  statusLabel: string
  sendingStatus: SENDING_STATUS | null
  period: string
  rawPeriod: string
  exigibility: string
  nature: DECLARATION_TYPE
  exigibilityOutdated: boolean
  exigibilityLabel: string
  editable: boolean
  lastSendingDate: string | null
  lastSendingId: number | null
  canBeReopened: boolean
  returnDeadlineDate: string | null
  resent: boolean
  /**
   * Mis manuellement à true dans le cas où l'utilisateur
   * ajoute/supprime la taxe d'apprentissage
   * afin d'afficher des message d'informations dans le résumé
   */
  haveAddOrDeleteApprenticeTax?: boolean
}

export type ReportDashboard = {
  id: number | null
  status: STATUS
  statusLabel: string
  sendingStatus: SENDING_STATUS | null
  period: Date
  rawPeriod: string
  nature: DECLARATION_TYPE
  name: string
  firstName: string
  exigibilityLabel: string
  editable: boolean
  lastSendingDate: string | null
  lastSendingId: number | null
  resent: boolean
}

export enum DECLARATION_TYPE {
  DSN_MENSUELLE = '01',
  DSN_FIN_CONTRAT_TRAVAIL = '02',
  DSN_ARRET_TRAVAIL = '04',
  DSN_REPRISE_SUITE_ARRET_TRAVAIL = '05',
  DSN_FIN_CONTRAT_TRAVAIL_UNIQUE = '07',
  INCONNU = '00',
}

export enum DECLARATION_TYPE_LABEL {
  DSN_MENSUELLE = 'DSN mensuelle',
  DSN_FIN_CONTRAT_TRAVAIL = 'Signalement Fin du contrat de travail',
  DSN_ARRET_TRAVAIL = 'Signalement Arrêt de travail',
  DSN_REPRISE_SUITE_ARRET_TRAVAIL = 'Signalement Reprise suite à arrêt de travail',
  DSN_FIN_CONTRAT_TRAVAIL_UNIQUE = 'Signalement Fin du contrat de travail unique',
  INCONNU = 'Inconnu',
}

export type SendingReport = {
  dateReception: string
  nature: DECLARATION_TYPE
  periode: string
  rapports: Report[]
}

export type Report = {
  message: {
    codeCrm: string
    detail: string
    titre: string
  }
  organisme: string
}

export enum REPORT_STATUS {
  VALIDATED = 'Déclaration validée',
  ACCEPTED_WITH_REMARKS = 'Déclaration acceptée avec des remarques',
  WAITING_RESPONSE = 'Déclaration en attente de réponse',
  RECEIVED = 'Déclaration reçue',
  ON_GOING = 'En cours',
  REJECTED = 'Rejeté',
}

export type CompanyDeclarationResume = {
  id?: number
  libelle?: string
  raisonSociale?: string
  siret?: string
  statut: STATUS
}

export type Anomaly = {
  code: string
  emetteurLabel: { emetteur: string; natureFlux: string; labelBloquant: string; labelNonBloquant: string }
  erreurStatus: 'NON_BLOQUANT' | 'BLOQUANT'
  message: string
  nombre: number
}

export type IndidividualDeclarationResume = {
  aBloque: boolean
  anomalies: Anomaly[]
  bloquages: Blocking[]
  debutContrat: string
  dsnIndividuId: number
  libelle: string
  miseAJourDsn: string
  nom: string
  numeroContrat: string
  numeroNtt: null
  numeroSecu: string
  prenom: string
  salarieId: number
  contratId: number
  statut: STATUS
}

export type Blocking = {
  code: BLOCKING_CODE
  libelle: string
}

export enum BLOCKING_CODE {
  MAJ_FICHE_OC = 'MAJ_FICHE_OC',
  CODE_ATMP_ENTREPRISE_NON_VALIDE = 'CODE_ATMP_ENT_NON_VALIDE',
}

export type MonthlyDeclarationSummary = {
  anomalies: Anomaly[]
  bloquages: Blocking[]
  dsnEntreprise: CompanyDeclarationResume
  dsnIndividuelles: IndidividualDeclarationResume[]
  dsnNeant?: boolean
  messageSuiteChangementPrevoyance?: boolean
  modeLecture?: boolean
  periode?: string
  raisonSociale?: string
  regularisations: { periodes: string[] }
  statutEnvoi: SENDING_STATUS
  supprimable?: boolean
  modifiable: boolean
  dateLimiteEnvoi: string | null
}

export type CotisationSummaries = {
  serviceStatus: ServiceStatus[]
  errors: string[]
  cotisationsSummary: CotisationSummary[]
}

export type CotisationSummary = {
  organisme: string
  cotisation: { label: string; montant: number }[]
  particularites: string
}

export type ServiceStatus = {
  code?: string
  message?: string
}

export type IndividualDeclaration = {
  id: number
  period: { monthYear: string; startDate: string; endDate: string }
  contractId: number
  status: STATUS
  employee: { id: number; name: string; firstName: string }
  extraHours: EditDatableValue[]
  absences: EditDatableValue[]
  workingTime: {
    paidWorkingTime: number
    workingUnit: string
  }
  salary: {
    uncappedMonthlyGross: number
    grossSubjectToUnemploymentInsuranceContributions: number
    reconstitutedGrossSalary: number
    basicGrossSalary: number
  }
  base: {
    pssModified: number | null
    fields: CodeAmount[]
  }
  baseComponents: EditDatableValue[]
  otherIncomes: EditDatableValue[]
  contributions: EditDatableValue[]
  exemptions: EditDatableValue[]
  bonus: EditDatableValue[]
  compensations: EditDatableValue[]
  withHoldingTax: {
    tauxPAS: number | null
    tauxPASCurrentMonth: number | null
    tauxPASPreviousMonth: number | null
    base: number
    amount: number
    isTauxPASDiffFromPreviousMonth: boolean
  }
  payment: {
    paymentDate: any
    netPayable: number
    netPayableAfterTax: number
  }
  totals: {
    netSocialAmount: number
    netTaxIncome: number
    netPayableBeforeTax: number
    grossIJSSAmount: number
    deduction: number
    netTaxIncomeApprenticeDetails: NetTaxIncomeApprenticeDetails | null
  }
  admin: {
    shouldRecalculateGeneralReduction: boolean
  }
  needRecalculateContributions: boolean
  readonly: boolean
  focusPanel?: INDIVIDUAL_DECLARATION_PANEL | null
  lastEditedPanel?: INDIVIDUAL_DECLARATION_PANEL
  warningMap?: Record<INDIVIDUAL_DECLARATION_PANEL, MappingError>
  errorMap?: Record<INDIVIDUAL_DECLARATION_PANEL, MappingError>
}

export type CompanyDeclaration = {
  id: number
  period: { monthYear: string; startDate: string; endDate: string }
  readonly: boolean
  status: STATUS
  dockets: CompanyDeclarationDocket[]
  cibtp?: {
    salesRevenue: number
    numberOfAssociatedInterimHours: number
  }
  errorMap?: Record<COMPANY_DECLARATION_PANEL, MappingError>
  hasIndividualDeclarationMoreRecent?: boolean
  isEmpty: boolean
}

export type CompanyDeclarationDocket = {
  socialOrganization: SOCIAL_ORGANIZATIONS
  label: string
  identify: string
  total: number
  contributions: CompanyDeclarationContribution[]
  specificContributions: CompanyDeclarationContribution[]
}

export type RegularizationPanel = {
  id: INDIVIDUAL_DECLARATION_PANEL
  contributions: EditDatableValue[]
}

export type CompanyDeclarationContribution = EditDatableValue & {
  count?: string
  nature?: 'C' | 'E'
  codeInsee?: string
  order?: string
  isAmountEditable?: boolean
  isBaseEditable?: boolean
  isRateEditable?: boolean
  isEditableWithLink?: boolean
  isRefreshable?: boolean
  isDeletable?: boolean
}

export type EditDatableColumn = {
  id: string
  header: string
  type: 'label' | 'string' | 'number' | 'amount' | 'rate' | 'date' | 'dropdown' | 'tag'
  editable: boolean
}

export type EditDatableValue = {
  id?: string
  key: string
  label?: string
  group?: string
  base?: { value: number | null; required: boolean; canBeZero: boolean; computedValue?: number }
  rate?: { value: number | null; required: boolean; canBeZero: boolean }
  otherRate?: { value: number | null; required: boolean; canBeZero: boolean }
  hours?: { value: number | null; required: boolean; canBeZero: boolean }
  amount?: { value: number | null; required: boolean; canBeZero: boolean; computedValue?: number }
  otherBase?: { value: number | null; required: boolean; canBeZero: boolean }
  startDate?: { value: any; required: boolean; dateDetails?: ABSENCE_DETAIL; hours?: number }
  endDate?: { value: any; required: boolean; dateDetails?: ABSENCE_DETAIL; hours?: number }
  tag?: { value: EDIT_DATATABLE_TAG | null; required: boolean }
  item?: EditDatableItem
  codeBase?: string // rajouter pour les renvoyer au back (notamment pour les cotisations en 059)
  codeInsee?: string // rajouter pour les renvoyer au back (notamment pour les cotisations en 059)
  string?: { value: string | null; required: boolean }
  code: string
  haveModal?: boolean
}

export type EditDatableItem = {
  value: string | null
  required: boolean
  options: (ObjKeyValueString & { withAmount?: boolean; withHours?: boolean; withDates?: boolean })[] // specificity for absence options
  references?: any[]
  loading: boolean
  needCall?: boolean
  info?: { period: string; panel: INDIVIDUAL_DECLARATION_PANEL | COMPANY_DECLARATION_PANEL; id: number | string }
}

export enum EDIT_DATATABLE_TAG {
  BONUS = 'Primes',
  BENEFITS_IN_KIND = 'Avantages en nature',
  UNREFERENCED = 'Non référencé',
}

export type AbsenceOptions = {
  code: string
  libelle: string
  avecDates: boolean
  avecRemuneration: boolean
  avecHeures?: boolean
}

export type IndividualDeclarationPayload = {
  absences: AbsencePayload[]
  autreRevenusBruts: CodeAmountPayload[]
  bases: CodeAmountPayload[]
  complement: {
    changementPss: boolean
    pss: string
  }
  composantBases: CodeAmountPayload[]
  contratId?: number
  dateDebut: string
  dateFin: string
  entrepriseId?: number
  cotisations: ContributionPayload[]
  exonerations: ExemptionPayload[]
  heuresSupplementairesOuExceptionnelles: ExtraHoursPayload[]
  indemnites: CodeAmountPayload[]
  nom?: string
  prenoms?: string
  primes: CodeAmountPayload[]
  salaires: CodeAmountPayload[]
  salarieId?: number
  status?: STATUS
  tempsTravailBases: { code: string; mesure: number; uniteMesure: string }[]
  versement: {
    abattementPas: number
    assiettePas: number
    avecIJSSBrut: boolean
    brut: number
    dateVersement: string
    diffTauxPasMoisPrecedent: boolean
    montantIJSSBrut: number
    montantNet: number
    montantNetApresIR: number
    montantNetSocial: number | null
    montantPas: number
    netFiscal: number
    netFiscalPot: number | null
    periode: string
    tauxPas: number | null
    tauxPasMoisCourant: number | null
    tauxPasMoisPrecedent: number | null
    detailRnfApprentiStagiaire: NetTaxIncomeApprenticeDetailsPayload
  }
  modeLecture?: boolean
  desactivationCalculAutoReductionGenerale?: boolean
  besoinRecalculerCotisations: boolean
}

export type DefaultValuePayload = {
  absences: AbsencePayload[]
  autreRevenusBruts: CodeAmountPayload[]
  bases: CodeAmountPayload[]
  complement: {
    changementPss: boolean
    pss: string
  }
  composantBases: CodeAmountPayload[]
  dateDebut: string
  dateFin: string
  cotisations: ContributionPayload[]
  exonerations: ExemptionPayload[]
  heuresSupplementairesOuExceptionnelles: ExtraHoursPayload[]
  indemnites: CodeAmountPayload[]
  primes: CodeAmountPayload[]
  salaires: CodeAmountPayload[]
  tempsTravailBases: { code: string; mesure: number; uniteMesure: string }[]
}

export type AbsencePayload = {
  sType: string
  sDebut: string | Date
  sFin: string | Date
  nombreHeures: number | null
  montant: number
}

export type ExtraHoursPayload = {
  code: string
  heures: number
  montant: number
}

export type NetTaxIncomeApprenticeDetailsPayload = {
  smicAnnuel: number | null
  cumulPrecedent: number | null
  netImposableBulletin: number | null
  cumulCourant: number | null
  rnf: number | null
  rnfProvisoire: number | null
}

export type NetTaxIncomeApprenticeDetails = {
  annualMinimumWages: number | null
  previousAccumulation: number | null
  netTaxableBulletin: number | null
  currentAccumulation: number | null
  netTaxIncome: number | null
  temporaryNetTaxIncome: number | null
}

export type ContributionPayload = {
  code: string
  identifiant: string
  codeInsee?: string // non utilisé - mais utile pour le back
  codeBase?: string // non utilisé - mais utile pour le back
  montantAssiette: number
  montantCotisation: number
  taux: number
}

export type CodeAmount = {
  code: string
  amount: number
}

export type CodeAmountPayload = {
  code: string
  montant: number
}

export type ExemptionPayload = {
  code: string
  montantAssiette: number
  montantExoneration: number
}

export enum INDIVIDUAL_DECLARATION_BASES {
  SUBJECT_CAPPED_GROSS = '02', // Assujettie brut plafonné
  SUBJECT_CAPPED_GROSS_COVID19 = '02-COVID-19', // Assujettie brut plafonné hors Activité Partielle COVID-19
  SUBJECT_UNCAPPED_GROSS = '03', // Assujettie brut deplafonné
  SUBJECT_UNCAPPED_GROSS_COVID19 = '03-COVID-19', // Assujettie brut deplafonné hors Activité Partielle COVID-19
  SUBJECT_UNEMPLOYMENT_INSURANCE_CONTRIBUTION = '07', // Assujettie contribution assurance chômage
  SUBJECT_GENERAL_SOCIAL_CONTRIBUTION = '04', // Assujettie contribution sociale généralisée
  SUBJECT_GENERAL_SOCIAL_CONTRIBUTION_COVID19 = '04-COVID-19', // Assujettie contribution sociale généralisée sur Activité Partielle COVID-19
  FLAT_RATE_BASIS_FOR_SOCIAL_SECURITY_CONTRIBUTIONS = '11', // Base forfaitaire cotisations Sécurité Sociale
  PAID_LEAVE_FUNDS_GROSS_CONTRIBUTION_BASE = '20', // Caisses de congés payés - Base brute de cotisations
  SPECIFIC_GROSS_BASIS = '22', // Base brute spécifique
  SPECIFIC_CAPPED_BASIS = '24', // Base plafonnée spécifique
  SUBJECT_SHOW_LEAVE = '27', // Assujettie Congés spectacles
  SUBJECT_PENSION_AFFILIATION = '31', // Assujettie affiliation prevoyance
  CAPPED_BASE_ICP = '45', // Base plafonnée ICP (indemnité de congés payés)
  MOBILITY_PAYMENT_BASIS = '57', // Base versement mobilité
  CIBTP_BASE_A_PROFESSIONAL_CONSTRUCTION_BODY = '36', // CIBTP - Base A de cotisations organisme professionnel BTP/FNB-CAPEB
  CIBTP_BASE_B_PROFESSIONAL_CONSTRUCTION_BODY = '39', // CIBTP - Base B de cotisations organisme professionnel BTP/SEBTPG
  CIBTP_BASE_C_PROFESSIONAL_CONSTRUCTION_BODY = '40', // CIBTP - Base C de cotisations organisme professionnel BTP hors FNB-CAPEB-SEBTPG
  CIBTP_GROSS_BASE_PERMANENT_OPPBTP_CONTRIBUTIONS = '21', // CIBTP - Base brute de cotisations OPPBTP permanents
  CIBTP_CAPPED_BASE_BAD_WEATHER_CONTRIBUTIONS_MAJOR_PUBLIC_WORKS = '34', // CIBTP - Base plafonnée de cotisations intempéries gros oeuvre travaux publics
  CIBTP_CAPPED_BASE_BAD_WEATHER_CONTRIBUTIONS_SECOND_WORK = '35', // CIBTP - Base plafonnée de cotisations intempéries second oeuvre
}

export enum COTISATIONS {
  APPRENTICE_TAX = '130-1',
  PREVOYANCE = '059',
}

export enum INDIVIDUAL_DECLARATION_SALARY {
  UNCAPPED_MONTHLY_GROSS = '001',
  GROSS_SUBJECT_TO_UNEMPLOYMENT_INSURANCE_CONTRIBUTIONS = '002',
  RECONSTITUTED_GROSS_SALARY = '003',
  BASIC_GROSS_SALARY = '010',
}

export type CreateDeclarationPayload = {
  periode: string
  entrepriseId: number
  dsnSuite?: boolean
}

export enum INDIVIDUAL_DECLARATION_PANEL {
  WORKING_TIME = 'temps-de-travail',
  ABSENCES = 'absences',
  EXTRA_HOURS = 'heures-supplementaires',
  SALARY = 'salaire',
  BONUS = 'primes',
  COMPENSATIONS = 'indemnites',
  OTHER_INCOME = 'autres-revenus',
  BASES = 'bases',
  CONTRIBUTIONS = 'cotisations',
  EXEMPTIONS = 'exonerations',
  TOTALS_BEFORE_TAXATION = 'totaux-avant-imposition',
  WITH_HOLDING_TAX = 'prelevement-source',
  PAYMENT = 'versement',
}

export enum INDIVIDUAL_DECLARATION_PANEL_LABEL {
  WORKING_TIME = 'Temps de travail',
  ABSENCES = 'Absences',
  EXTRA_HOURS = 'Heures supp. et exceptionnelles',
  SALARY = 'Salaire de base',
  BONUS = 'Primes',
  COMPENSATIONS = 'Indemnités',
  OTHER_INCOME = 'Autres revenus bruts soumis à cotisations',
  BASES = 'Bases',
  CONTRIBUTIONS = 'Cotisations',
  EXEMPTIONS = 'Exonérations',
  TOTALS_BEFORE_TAXATION = 'Totaux avant imposition',
  WITH_HOLDING_TAX = 'Prélèvement à la source',
  PAYMENT = 'Versement',
}

export type MappingError = {
  fields: string[]
  messages: string[]
  messagesPerField: Map<string, string[]>
}

export type MappingErrorPayload = {
  fields: string[]
  messages: string[]
  messagesPerField?: Record<string, string[]>
  name?: string | null
}

export type CompanyDeclarationPayload = {
  modeLecture?: boolean
  entrepriseId?: number
  status?: STATUS
  neant?: boolean
  dateDebut?: string
  dateFin?: string
  bordereaux: CompanyDeclarationBordereau[]
  assujettissementFiscaux: CompanyDeclarationAssujettissement[]
  cotisationDoeths: []
  cotisationSoldeTaxeApprentissages: []
  error?: null
  mapErrorsByDisplayBlock?: Record<COMPANY_DECLARATION_PANEL, MappingErrorPayload>
  hasDsnIndividuellePlusRecente?: boolean
}

export type CompanyDeclarationBordereau = {
  identifiant: string
  ops?: string
  codeOps: SOCIAL_ORGANIZATIONS
  labelIdentifiant: string
  totalCotisation: number
  cotisations: CompanyDeclarationCotisation[]
  cotisationsSpeciques: CompanyDeclarationCotisationSpecifique[]
}

export type CompanyDeclarationAssujettissement = {
  codeTaxe: string
  montant: number
  label?: string
}

export type CompanyDeclarationCotisation = {
  codeType: string
  identifiant?: string
  montantAssiette: number
  montantCotisation: number
  taux: number | null
  qualifiant?: string
  label?: string
  ordre?: string
  codeInsee?: string
  nature?: 'C' | 'E'
  codeOps?: string
  assietteSaisissable?: boolean
  cotisationSaisissable?: boolean
  isTauxModifiable?: boolean
  isEditableWithLink?: boolean
  isRefreshable?: boolean
  isDeletable?: boolean
}

export type CompanyDeclarationCotisationSpecifique = {
  codeCotisation: string
  codeOps?: SOCIAL_ORGANIZATIONS
  codeOrganisme?: string
  identifiantAdhesion?: string
  label?: string
  montantCotisation: number
  referenceContrat: string
}

export enum COMPANY_DECLARATION_PANEL {
  AGIRC_ARRCO = 'agirc-arrco',
  CIBTP = 'cibtp',
  CONGES_SPECTACLES_AUDIENS = 'conges-spectacles',
  DGFIP = 'dgfip',
  ORGANISME_COMPLEMENTAIRE = 'organisme-complementaire',
  FRANCE_TRAVAIL = 'france-travail',
  URSSAF = 'urssaf',
  TAX_LIABILITY = 'assujettissement-fiscal',
}

export enum COMPANY_DECLARATION_PANEL_LABEL {
  AGIRC_ARRCO = 'AGIRC-ARRCO',
  CIBTP = 'CIBTP',
  CONGES_SPECTACLES_AUDIENS = 'Congés spectacles - AUDIENS',
  DGFIP = 'DGFIP',
  ORGANISME_COMPLEMENTAIRE = 'Organisme complémentaire',
  FRANCE_TRAVAIL = 'France Travail',
  URSSAF = 'URSSAF',
  TAX_LIABILITY = 'Assujettissement fiscal',
}

export enum TAX_LIABILITY {
  APPRENTICESHIP_TAX_MAIN_PART = '001',
  APPRENTICESHIP_TAX_ADDITIONAL_PART = '003',
}

export type ClassicCtpDetails = {
  code: string
  label: string
  periode: string
  codeCotisationAssocie: string
  libelleCotisationAssociee: string
  totalAssiette: number
  totalMontant: number
  cotisationsSalaries: ClassicCtpDetailsContribution[]
}

export type ClassicCtpDetailsContribution = {
  nom?: string
  prenom?: string
  numeroSecu?: string
  idContrat?: string
  contratDateDebut?: string
  idDsnIndividuelle: number
  assiette: number
  taux: number
  montant: number
  isReadOnly?: boolean
  code?: string
}

export type ClassicCtpDetailsPayload = {
  code: string
  codeCotisationAssocie: string
  cotisationsSalaries: ClassicCtpDetailsContribution[]
}

export type RegularizationClassicCtpDetails = {
  code: string
  label: string
  codeCotisationAssocie: string
  libelleCotisationAssociee: string
  totalAssiette: number
  totalMontant: number
  cotisationsSalaries: RegularizationClassicCtpDetailsContribution[]
}

export type RegularizationClassicCtpDetailsContribution = {
  cId?: number
  nom?: string
  prenom?: string
  numSecuNumNtt?: string
  assiette: number
  taux: number
  montant: number
  isReadOnly?: boolean
}

export type RegularizationClassicCtpDetailsPayload = {
  contratId: number
  assiette: number
  taux: number
  montant: number
}

export type ClassicCtpDetailsResponse = {
  codeType: string
  qualifiant: string
  codeOps: string
  montantAssiette: number
  montantCotisation: number
  taux: number
}

export type LearningTaxCtpDetails = {
  code: string
  label: string
  periode: string
  anneeRattachement: number
  codeAssocie: string
  cotisationAnnuelle: number
  assietteAnnuelle: number
  cotisations: ClassicCtpDetailsContribution[]
}

export type LearningTaxCtpDetailsContribution = {
  code: string
  label: string
  montant: number
  isReadOnly: boolean
}

export type LearningTaxCtpDetailsPayload = {
  code: string
  montant: number
  anneeRattachement: number
  assiette: number
}

export type GeneralReductionCtpDetails = {
  code: string
  label: string
  periode: string
  montantPartUrssaf: number
  montantPartRetraite: number
  salaries: GeneralReductionCtpDetailsEmployee[]
}

export type RegularizationGeneralReductionCtpDetails = {
  cId: number
  nomSalarie: string
  nom: string
  prenom: string
  numSecuNumNtt: string
  montant: number
}

export type GeneralReductionCtpPayload = {
  dsnId: number
  reductionUrssaf: number
  reductionRetraite: number
  ctp: string
}

export type RegularizationGeneralReductionCtpPayload = {
  contratId: number
  montant: number
}

export type GeneralReductionCtpSaveResult = {
  retraite: { codeCotisation: string; assiette: number; cotisation: number }
  urssaf: { ctp: string; assiette: number; ctpOppose?: string; ctpOpposeAssiette?: number }
}

export type GeneralReductionCtpDetailsEmployee = {
  nom: string
  prenom: string
  idContrat: string
  contratDateDebut: string
  partUrssaf: number
  partRetraite: number
  isReadOnly: boolean
  dsnId: number
}

export type DoethCtpDetails = {
  code: string
  label: string
  periode: string
  anneeRattachement: number
  montantCotisation: number
  cotisations: DoethCtpDetailsContribution[]
}

export type DoethCtpDetailsContribution = {
  code: string
  label: string
  montantCotisation: number
  isReadOnly: boolean
}

export type DoethCtpPayload = {
  code: string
  montantCotisation: number
  anneeRattachement: number
}

export type ContratSalarieEligible = {
  listeOrganismes: string[]
  listeSiretAgircArrcos: string[]
  salarieId: number
  contratId: number
  debutContrat: string
  finContrat: string | null
  numeroContrat: string
  typeContrat: string
  labelContrat: string
  nom: string
  prenoms: string
}

export type EligibleEmployeeContract = {
  organisms: string[]
  agircArrcoSirets: string[]
  employeeId: number
  contractId: number
  contractStart: string
  contractEnd: string | null
  contractNumber: string
  contractType: string
  contractLabel: string
  lastname: string
  firstname: string
}

export type OrganismeEligible = {
  codeOps: SOCIAL_ORGANIZATIONS
  identifiant: string
  infos: OrganismeEligibleInfo[]
  label: string
}

export type OrganismeEligibleInfo = {
  bloquant: true
  message: string
}

export type EligibleSocialOrganization = {
  code: string
  socialOrganization: SOCIAL_ORGANIZATIONS
  identify: string
  label: string
  messages: EligibleSocialOrganizationMessage[]
}

export type EligibleSocialOrganizationMessage = {
  blocking: boolean
  value: string
}

export enum REGULARIZATION_ELEMENT_TYPE {
  DOCKET = 'DOCKET',
  PANEL = 'PANEL',
}

export type RegularizationEmployeeContribution = {
  contratId: string
  salarieId: string
  bases: { code: INDIVIDUAL_DECLARATION_BASES; montant: number; label: string }[]
  cotisationsExonerations: { code: string; type: 'C' | 'E'; label: string; montant: number }[]
}

export type RegularizationEmployeeContributionPayload = {
  periodeARegulariser: string
  contratId: string
  identifiant: string
  cotisationExonerations: { code: string; montant: number }[]
}

export type RegularizationEmployeeBase = {
  code: INDIVIDUAL_DECLARATION_BASES
  montant: number
  label: string
}

export type RegularizationEmployeeBasePayload = {
  periodeARegulariser: string
  contratId: string
  bases: { code: string; montant: number }[]
}

export type RegularizationContractComplementaryOrganism = {
  code: string
  label: string
}

export type RegularizationEmployeeFranceTravail = {
  code: string
  libelle: string
  taux: number
  type: 'C' | 'E'
}

export type RegularizationEmployeeDgfip = {
  montantPas: number
  montantPasDeclare: number
  tauxPas: number
  tauxPasPrecedent: number
  diffTauxPas: number
  dgfipRegularisationType: 'parTauxNetFiscal' | 'parNetFiscal' | 'parTaux' | 'parNetFiscalSansPas'
  assiettePas: number
  brutDeclarePas: number
  abattementPas: number
  partNonImposablePas: number
  assiettePasDeclare: number
  dgfipRegularisationTypeLabel: string
  sansDsnIndividuelleReference: boolean
  nonTransmissionMontantPas: boolean
  estApprenti: boolean
}

export type RegularizationEmployeeDgfipPayload = {
  periodeARegulariser: string
  contratId: string
  assiettePas: number
  tauxPas: number
  montantPas: number
  dgfipRegularisationType: string
  abattementPas: number
  partNonImposable: number
  brutDeclarePas: number
}

export type Regularization = {
  period: string | null
  dockets: CompanyDeclarationDocket[]
  panels: RegularizationPanel[]
  readonly: boolean
}

export type RegularizationPayload = {
  periodeARegulariser: string
  readOnly?: boolean
  regularisationAgircArrco: RegularizationAgircArrcoPayload[]
  regularisationCongesSpectacles: { contratSalaries?: RegularizationCongesSpectaclePayload[] }
  regularisationDgfip: { contratSalaries?: RegularizationDGFIPPayload[]; contratSalarieIds?: string[] }
  regularisationOrganismeComplementaire: RegularizationComplementaryOrganismPayload[]
  regularisationUrssaf: { dsnEntrepriseCotisations: CompanyDeclarationCotisation[] }
  regularisationCiBtp: { agregationEntreprise: RegularizationCIBTPPayload } | null
  regularisationPoleEmploiSpectacles: { contratSalaries: RegularizationFranceTravailPayload[] } | null
  regularisationAutresElements: {
    regulTempsTravailBases: RegularizationWorkingTimePayload[]
    regulAbsences: RegularizationAbsencePayload[]
    regulPrimes: RegularizationBonusPayload[]
    regulIndemnites: RegularizationBonusPayload[]
    regulBases: { contratIds: number[] }
    regulVersements: RegularizationPaymentPayload[]
  }
}

export type RegularizationPaymentPayload = {
  contratId: number
  montantNetSocial: number
}

export type RegularizationFranceTravailPayload = {
  contratId: number
  taux: number
  codeCotisation: string
  libelleCotisation?: string
  montantAssiette: number
  montantCotisation: number
}

export type RegularizationAgircArrcoContractPayload = {
  contratId: number
  deplafonne: number
  plafonne: number
  montantRegularisation: number
}

export type RegularizationAgircArrcoPayload = {
  identifiant: string
  contratSalaries?: RegularizationAgircArrcoContractPayload[]
  contratSalarieIds?: string[]
}

export type RegularizationCongesSpectaclePayload = {
  contratId: number
  montant: number
}

export type RegularizationDGFIPPayload = {
  contratId: number
  montantRegulPas: number
}

export type RegularizationComplementaryOrganismPayload = {
  codeOrganisme: string
  libelleCodeOrganisme: string
  regularisationOcDetailEtablissements: RegularizationOCEtablissementPayload[]
  regularisationOcDetailSalarieContrats: RegularizationOCContractPayload[]
}

export type RegularizationOCContractPayload = {
  contratId: number | string
  montantCotisation: number
  referenceContratOc: string
  libelleContratOc?: string
}

export type RegularizationOCEtablissementPayload = {
  codeOrganisme?: number
  montantCotisation: number
  referenceContratOc: string
  libelleContrat?: string
}

export type RegularizationWorkingTimePayload = {
  contratId: number
  code: string
  mesure: number
  uniteMesure: string
  libelleMesure?: string
}

export type RegularizationAbsencePayload = {
  contratId: number
  type: string
  libelle?: string
  debut: string
  fin: string
  montant: number | null
  heures: number
}

export type RegularizationBonusPayload = {
  contratId: number
  code: string
  montant: number
  libelle?: string
}

export type RegularizationCIBTPPayload = {
  montantCA: number
  heuresInterimaires: number
}

export enum CIBTP_CONTRIBUTION_CODE {
  TURNOVER = '054',
  HOURS = '024',
}

export enum CIBTP_CONTRIBUTION_LABEL {
  TURNOVER = "Chiffre d'affaires utilisé pour le calcul de la cotisation",
  HOURS = "Nombre d'heures d'intérim utilisé pour le calcul de la cotisation",
}

export type UrssafContribution = ObjKeyValueString & {
  qualifiant?: string
  codeInsee?: string
}
