import { dateFRtoBasic } from '@/utils'
import {
  ComplementaryOrgMembershipsPayload,
  Contract,
  ContractPayload,
  SPECIFIC_STATUS,
  StatusSpecificationsPayload,
} from './contract.model'
import { IndividualFiles } from './company.model'

export class Helper {
  static storingDataFromAPI(contract: ContractPayload): Contract {
    const contractForStore: Contract = {
      id: contract.id,
      globalInformations: {
        contractType: contract.informationsGenerales.nature,
        startDate:
          typeof contract.informationsGenerales.dateDebut === 'string'
            ? new Date(dateFRtoBasic(contract.informationsGenerales.dateDebut))
            : contract.informationsGenerales.dateDebut,
        endDate:
          typeof contract.informationsGenerales.dateFin === 'string'
            ? new Date(dateFRtoBasic(contract.informationsGenerales.dateFin))
            : contract.informationsGenerales.dateFin,
        label: contract.informationsGenerales.libelleEmploi,
        workPlace: contract.informationsGenerales.codeInseeLieuTravail,
      },
      statusSpecifications: {
        specificStatus: this.determineSpecificStatus(contract.etapeStatus),
        publicAssistancePolicy: contract.etapeStatus.dispositifAidePublique,
        preparedTraining: contract.etapeStatus.diplomeApprenti,
        hiringReason: contract.etapeStatus.motifRecours,
        casualEntertainmentWorkerType: contract.etapeStatus.typeIntermitentSpectacle,
      },
      socioProfessionalCategory: {
        group: contract.categoriesSocioProfessionnelles.famillePcsEse,
        category: contract.categoriesSocioProfessionnelles.pcsEse,
        complement: contract.categoriesSocioProfessionnelles.complementPcsEse,
        section: contract.categoriesSocioProfessionnelles.branchePcsEse,
        jobClassification: contract.categoriesSocioProfessionnelles.classificationMetier,
        ableToContributeToExecutiveRetirement: { loading: false, result: null },
      },
      workingHoursAndWages: {
        workingUnit: contract.tempsTravailEtSalaire.uniteMesure,
        monthlyLegalCompanyHours: contract.tempsTravailEtSalaire.quotiteEntreprise,
        monthlyContractHour: contract.tempsTravailEtSalaire.quotiteContrat,
        workingTimeMode: contract.tempsTravailEtSalaire.modaliteTemps,
        monthlyGrossWage: contract.tempsTravailEtSalaire.brutMensuel,
      },
      additionalInformations: {
        complementaryPension: contract.informationsComplementaires.retraiteComplementaire,
        professionalExpenseRate: contract.informationsComplementaires.tauxFraisProfessionnels
          ? contract.informationsComplementaires.tauxFraisProfessionnels
          : 0,
        noUnemploymentInsuranceContribution: contract.informationsComplementaires.pasAssuChomage,
        additionalContributionAgingRisk: contract.informationsComplementaires.tpsPartielCotizComplemRisqueVieillesse,
        workerAbroad: contract.informationsComplementaires.detacheExpatrie,
        arduousness: contract.informationsComplementaires.penibilite,
        riskFactor: contract.informationsComplementaires.facteursRisquePenibilite,
        disabledWorker: contract.informationsComplementaires.codeTravailleurHandicape
          ? contract.informationsComplementaires.codeTravailleurHandicape
          : '00',
        workAccidentRate: contract.informationsComplementaires.ordreTauxAt,
      },
      complementaryOrgMemberships: [],
      endOfContract: {
        receivedAJobOffer: contract.detailFinCdd?.avecPropositionEmbaucheApresCdd ?? false,
        jobOfferDeclined: contract.detailFinCdd?.refusPropositionEmbauche ?? false,
      },
      active: contract.actif,
      contractNumber: contract.numero,
    }
    return contractForStore
  }

  static determineSpecificStatus(statusSpecifications: StatusSpecificationsPayload): string | null {
    if (statusSpecifications.dirigeantSalarie) return SPECIFIC_STATUS.EMPOYEE_MANAGER
    else if (statusSpecifications.apprentiSalarie) return SPECIFIC_STATUS.EMPLOYEE_APPRENTICE
    else if (statusSpecifications.agentPriveFonctionPublique) return SPECIFIC_STATUS.PRIVATE_CIVIL_SERVICE_AGENT
    else if (statusSpecifications.aideDomicilePersonneFragile) return SPECIFIC_STATUS.HOME_HELP_FOR_FRAGILE_PEOPLE
    else if (statusSpecifications.typeIntermitentSpectacle) return SPECIFIC_STATUS.INTERMITTENT

    return null
  }

  static constructComplementaryOrgPayload(complementaryOrg: IndividualFiles): ComplementaryOrgMembershipsPayload {
    const payload: ComplementaryOrgMembershipsPayload = {
      identifiantTechniqueAdhesion: complementaryOrg.identifiantTechniqueAdhesion,
      codeOrganisme: complementaryOrg.codeOrganisme,
      codePopulation: complementaryOrg.codePopulation,
      referenceContrat: complementaryOrg.referenceContrat,
      codeDelegataireGestion: complementaryOrg.codeDelegataireGestion,
      codeOption: complementaryOrg.codeOption,
    }
    return payload
  }
}
