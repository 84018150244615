<script setup lang="ts">
import { Helper } from '@/models/declaration.helper'
import { computed } from 'vue'

type Props = {
  text: string | string[]
  link?: string
}

const props = defineProps<Props>()

const tooltip = computed(() => {
  if (props.text && Array.isArray(props.text)) {
    Helper.transformStringArrayToHtmlList(props.text)
  }
  return props.text
})
</script>
<template>
  <a v-if="props.link" :href="props.link" target="_blank" rel="noreferrer" class="no-underline text-color">
    <i class="pi pi-question-circle" style="font-size: 1.5rem" v-tippy="tooltip" />
  </a>
  <i v-else class="pi pi-question-circle" style="font-size: 1.5rem" v-tippy="tooltip" />
</template>
