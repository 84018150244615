<script setup lang="ts">
import { useVgt } from 'vue-guided-tour'
import { onMounted, ref } from 'vue'
import { useMainStore } from '@/stores/main'
import { isMobileDevice } from '@/utils'

const onboardingTour = useVgt()
const mainStore = useMainStore()
const width = ref()

const sidebarSteps = ref([
  {
    title: 'Bienvenue sur la nouvelle interface TeleDSN !',
    content: 'Laissez-nous vous présenter en quelques étapes comment naviguer dans cette interface et gérer vos DSN',
    popover: {
      width: '550px',
    },
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_2')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-1',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      "La barre latérale de navigation vous permet d'accéder rapidement aux principales fonctionnalités de TeleDSN.",
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_3')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_1')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-2',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      'L’entreprise sur laquelle vous travaillez est visible dans ce menu. Dans le cas où vous gérez plusieurs entreprises, basculez d’une entreprise à l’autre avec ce menu.',
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_4')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_2')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-3',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      "Le bouton « Besoin d'aide » vous permet d’accéder à un guide utilisateur et une FAQ pour vous aider dans l'utilisation de l'application. Vous pouvez également contacter le support via cette rubrique.",
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_5')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_3')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-4',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      'Ce bouton minimise la barre latérale. En mode réduit, la barre latérale revient temporairement à sa taille normale au survol de la souris ou en recliquant sur la flèche pour rester de nouveau sur sa forme initiale',
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_4')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
])

const headerSteps = ref([
  {
    title: 'Bienvenue sur la nouvelle interface TeleDSN !',
    content: 'Laissez-nous vous présenter en quelques étapes comment naviguer dans cette interface et gérer vos DSN',
    popover: {
      width: '550px',
    },
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_2')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-1',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content: "La barre  de navigation vous permet d'accéder rapidement aux principales fonctionnalités de TeleDSN.",
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_3')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_1')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-2',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      'L’entreprise sur laquelle vous travaillez est visible dans ce menu. Dans le cas où vous gérez plusieurs entreprises, basculez d’une entreprise à l’autre avec ce menu.',
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_4')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_2')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-3',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      "Le bouton « Besoin d'aide » vous permet d’accéder à un guide utilisateur et une FAQ pour vous aider dans l'utilisation de l'application. Vous pouvez également contacter le support via cette rubrique.",
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_5')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_3')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-4',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      "Ce bouton permet d'accéder aux informations liées à votre compte (entreprises, abonnements, factures, identifiants et paramètres de l'interface)",
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_4')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
])

onMounted(() => {
  width.value = window.parent.innerWidth
  if (mainStore.triggerOnBoardingTour && !isMobileDevice()) {
    onboardingTour.start(0)
  }
})
</script>
<template>
  <vue-guided-tour :steps="width < 1400 ? headerSteps : sidebarSteps">
    <template #nav="{ isFirstStep, isLastStep }">
      <div class="vgt__nav">
        <Button
          v-if="isFirstStep"
          @click="onboardingTour.exit"
          label="Peut-être plus tard"
          severity="secondary"
          size="small"
          rounded
          class="mr-2"
        />
        <Button
          v-if="!isFirstStep"
          @click="onboardingTour.prev"
          label="Retour"
          severity="secondary"
          size="small"
          rounded
          class="mr-2"
        />
        <Button v-if="isLastStep" @click="onboardingTour.exit" label="Quitter" size="small" rounded class="ml-2" />
        <Button
          v-else
          @click="onboardingTour.next"
          :label="isFirstStep ? 'Commencer' : 'Suivant'"
          :icon="isFirstStep ? 'pi pi-play' : 'pi pi-chevron-right'"
          iconPos="right"
          size="small"
          rounded
          class="ml-2"
        />
      </div>
    </template>
    <template #content="{ stepIndex, step }">
      <div class="p-2">
        <h3 v-if="step.title">{{ step.title }}</h3>
        <div :class="stepIndex === 0 ? '' : 'mt-3'">{{ step.content }}</div>
      </div>
    </template>
    <template #close>
      <div class="flex justify-content-end">
        <Button
          @click="onboardingTour.exit"
          icon="pi pi-times"
          text
          rounded
          style="color: #6b7280 !important"
          severity="contrast"
          class="absolute p-dialog-header-icon p-dialog-header-close p-link close-btn"
        />
      </div>
    </template>
  </vue-guided-tour>
</template>
<style scoped>
.close-btn {
  top: 3px;
  right: 0px;
}
</style>
