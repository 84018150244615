export type UserGuide = {
  id: string
  title: string
  content: string
}

const content: UserGuide[] = [
  {
    id: 'introduction',
    title: 'Introduction',
    content: `<p>La nouvelle interface Teledsn propose une interface plus moderne et plus aérée que l’ancienne interface,
        tout en reprenant les principes généraux de l’ancienne interface. Le but est de simplifier la navigation avec moins de clics,
        en présentant l’information utile de manière regroupée au bon endroit et au bon moment.</p>
        <p>C’est aussi l’opportunité pour nous de vous proposer de nouvelles fonctionnalités, qui viendront très prochainement enrichir l’application.</p>    
        <p><u class="text-purple-500">Astuce :</u> Positionner la souris sur un élément (sans cliquer dessus) affiche un court
            texte explicatif.</p>
        <img src="/images/guide/tooltip_astuce.jpg" class="w-full" />
        `,
  },
  {
    id: 'navigation',
    title: 'La navigation',
    content: `<div class="flex w-full justify-content-center">
        <img src="/images/guide/navigation_laterale.jpg" /></div>
        <p>La navigation s’effectue via une barre de navigation latérale permettant d’accéder rapidement aux
        principales fonctionnalités, la flèche permet de passer la barre latérale en mode réduit. En mode réduit, elle revient temporairement à sa taille initiale si vous passez votre souris dessus. Pour remettre le menu à sa taille maximum, vous devrez cliquer sur la flèche accolée.</p>`,
  },
  {
    id: 'fil_ariane',
    title: 'Le fil d’ariane',
    content: `<img src="/images/guide/fil_ariane.jpg" class="w-full" />
        <p class="mt-1">Chaque écran s’affiche avec un fil d’ariane en haut qui vous permet de vous situer dans la navigation et
        où vous vous trouvez dans l’application. Tous les éléments sont cliquables afin de remonter facilement l’arborescence.</p>`,
  },
  {
    id: 'tableau_de_bord',
    title: 'Le tableau de bord',
    content: `<img src="/images/guide/tableau_de_bord.jpg" class="w-full" />
        <p>Le tableau de bord est l’écran principal qui regroupe les données créées régulièrement, à la fois les DSN, les salariés, et les signalements. Quelques états de synthèse sont également disponibles.</p>`,
  },
  {
    id: 'fiche_entreprise',
    title: 'Paramétrer son entreprise',
    content: `<img src="/images/guide/fiche_entreprise.jpg" class="w-full" />
        <p>Paramétrer son entreprise s’effectue en 5 étapes :</p>
        <ol>
            <li><b>Informations générales</b> permet de renseigner les données administratives de l’entreprise (SIREN, convention collective etc) ainsi que l’adresse.</li>
            <li><b>Contact</b> permet de renseigner le contact principal de l’entreprise. Cette information est transmise dans les données DSN.</li>
            <li><b>Organismes Sociaux</b> permet de déclarer tous les organismes sociaux auxquels l’entreprise est affiliée pour payer des cotisations</li>
            <li><b>Fiscalité</b> permet de renseigner différentes taxes auxquelles l’entreprise est assujettie</li>
            <li><b>Taux accident du travail</b> permet de renseigner le code risque et le/les taux AT de l’entreprise</li>
        </ol>
        <p>Le passage d’un écran à l’autre s’effectue par les boutons précédents / suivants et vos données saisies sont sauvegardées d’une étape à l’autre. En édition il est également possible d’accéder directement à une étape via le bandeau avec les numéros.</p>
        <p><u class="text-purple-500">Astuce :</u> en cas de doute, n'hésitez à consulter l'aide présente sur certains champs, via ces icônes <i class="pi pi-question-circle"></i>.</p>
        `,
  },
  {
    id: 'salaries_et_contrats',
    title: 'Gestion des salariés et de leurs contrats',
    content: `<p>Vous accédez à la gestion des salariés et de leurs contrats à partir de l’icône <i class="pi pi-users"></i> située dans la barre de navigation en haut de l’écran.</p>
        <img src="/images/guide/salaries_et_contrats.jpg" class="w-full" />
        <p>Chaque ligne dans le tableau représente un salarié, le bouton « Modifier les informations »  vous permet d’accéder aux détails du salarié tandis que que le bouton « Ajouter un salarié »  
        vous permet de créer facilement un nouveau salarié. Le bouton « Contrats <i class="pi pi-eye"></i> » en début de ligne permet d’afficher immédiatement en dessous le(s) contrat(s) du salarié.</p>`,
  },
  {
    id: 'fiche_salarie',
    title: 'Éditer les informations individuelles d’un salarié',
    content: `<img src="/images/guide/fiche_salarie.jpg" class="w-full" />
        <p>L’état civil et l’adresse du salarié s’éditent sur un même écran et la liste des contrats déjà existants pour le salarié apparaît sur la partie gauche, ce qui permet d’accéder en un simple clic aux données d’un contrat.</p>`,
  },
  {
    id: 'fiche_contrat',
    title: 'Éditer les données d’un contrat de travail',
    content: `<img src="/images/guide/fiche_contrat.jpg" class="w-full" />
        <p>Les informations d’un contrat de travail sont réparties sur 5 écrans et regroupées par catégories :</p>
        <ul>
            <li>Informations générales (type de contrat, libellé, régime…)</li>
            <li>Statut (dispositifs d’aide publique …)</li>
            <li>Catégorie socioprofessionnelle</li>
            <li>Temps de travail</li>
            <li>Informations complémentaires</li>
        </ul>`,
  },
  {
    id: 'declaration',
    title: 'Créer une déclaration sociale nominative (DSN)',
    content: `<p>Une DSN se compose de plusieurs formulaires :</p>
        <ul>
            <li>Des DSN individuelles (1 DSN par contrat actif de chaque salarié)</li>
            <li>D’éventuelles régularisations pour les périodes précédentes</li>
            <li>La DSN entreprise qui agrège et déclare les cotisations entreprises</li>
        </ul>
        <h4>Initialiser une DSN mensuelle</h4>
        <img src="/images/guide/tableau_de_bord_creer_dsn.jpg" class="w-full" />
        <p>La création d’une DSN mensuelle s’effectue depuis l'onglet « Mes déclarations » du tableau de bord en cliquant sur le bouton « Effectuer une DSN mensuelle »</p>
        <p>Une fenêtre s’ouvre afin de sélectionner la période que vous souhaitez déclarer, en général le mois précédent.</p>
        <img src="/images/guide/modal_creer_dsn_steps.jpg" class="w-full" />
        <h4>Résumé et statut d’une DSN mensuelle</h4>
        <p>Une fois la déclaration initialisée, vous accédez à un écran de résumé vous présentant les DSN individuelles de chaque salarié à compléter.
        Une fois ces DSN individuelles complétées, vous pouvez ensuite compléter la DSN entreprise et éventuellement ajouter des régularisations une fois les
        DSN individuelles complétées</p>
        <img src="/images/guide/summary_dsn.jpg" class="w-full" />
        <p><u class="text-purple-500">Bon à savoir :</u> la possibilité de compléter la DSN entreprise ne vous sera proposée qu’une fois les DSN mensuelles
        complétées mais vous pourrez bien-sûr retourner sur chaque DSN individuelle si vous devez y apporter des modifications.</p>
        <h4>Saisie d’une DSN individuelle</h4>
        <p>Une DSN individuelle se divise en plusieurs blocs d’informations qu’il est préférable de remplir de haut en bas mais vous pouvez naviguer d’un
        bloc à l’autre en scrollant de haut en bas ou en utilisant le menu de gauche qui vous positionne automatiquement sur le bon bloc. Lorsque vous prenez
        le focus sur un bloc, celui-ci est mis en évidence.</p>
        <img src="/images/guide/dsn_indiv.jpg" class="w-full" />
        <p>Les calculs se font automatiquement, la couleur de texte (noir ou gris) permet de reconnaitre qu’une cellule est édtiable ou non. Même si certains
        champs sont automatiquement calculés, il peut être possible d’en modifier la valeur si la cellule est éditable</p>
        <h4>Saisie d’une régularisation</h4>
        <img src="/images/guide/regul.jpg" class="w-full" />
        <p>Une régularisation permet de corriger des données d’une DSN d’une période précédente. Il peut être nécessaire d’effectuer des régularisations lorsque
        vous recevez des courriers ou notifications de la part des organismes sociaux lorsqu’ils vous informent d’erreurs ou d’écarts avec les données qu’ils attendent.
        Une régularisation s’effectue en saisissant l’écart par rapport à une valeur initialement déclarée.</p>
        <p><u class="text-purple-500">Exemple :</u> si un organisme vous informe que vous avez déclaré 100 dans une cotisation alors que vous auriez dû saisir 120,
        il faut alors saisir la valeur 20 dans la régularisation correspondante. L’ajout d’une régularisation s’effectue depuis l’écran de résumé de la DSN du mois courant que vous déclarez.</p>
        <h4>Saisie d’une DSN Entreprise</h4>
        <img src="/images/guide/dsn_entreprise.jpg" class="w-full" />
        <p>Vous retrouverez vos données saisies dans les DSN Individuelles, agrégées par bordereau d’organisme social (exemple URSSAF, AGIRC-ARRCO, DGFIP). Vérifier et corriger les éléments si besoin.</p>
        <h4>Envoyer une DSN</h4>
        <img src="/images/guide/envoyer_dsn.jpg" class="w-full" />
        <p>Une fois votre DSN complétée, vous pourrez accéder à la synthèse puis à l’envoi en cliquant sur le bouton « Synthèse et envoi »</p>
        <p><u class="text-purple-500">Bon à savoir :</u> l’envoi d’une DSN nécessite un abonnement actif pour l’entreprise concernée.</p>
        `,
  },
  {
    id: 'signalement',
    title: 'Créer un signalement',
    content: `<p>Il y a 3 types de signalement disponibles :</p>
        <ul>
            <li>Arrêt de travail</li>
            <li>Reprise arrêt de travail</li>
            <li>Fin de contrat de travail unique</li>
        </ul>
        <h4>Initialiser un signalement</h4>
        <img src="/images/guide/init_signalement.jpg" class="w-full" />
        <p>La création d’un signalement s’effectue depuis l’onglet « Mes signalements » du tableau de bord en cliquant sur le bouton « Effectuer un signalement »</p>
        <h4>Signaler un arrêt de travail</h4>
        <img src="/images/guide/signalement_arret_travail.jpg" class="w-full" />
        <p>Pour réaliser la déclaration de salaire pour le versement des indemnités journalières, il est indispensable d'avoir les informations du salarié préalablement historisées via les dépôts des DSN mensuelles précédentes.
        La procédure lorsque le nombre de DSN mensuelles reste insuffisant pour faire la déclaration de salaire pour le versement des indemnités journalières, est d'effectuer et transmettre une attestation de salaire en ligne pour le paiement des indemnités journalières sur le site Net-Entreprises.</p>
        <p>Pour signaler un arrêt de travail, vous devez sélectionner le salarié concerné ainsi que le motif d'arrêt de travail et la date de début d'arrêt. Selon le motif, de nouveaux champs peuvent apparaître et seront eux aussi à complétés. (ex : Date d'accident)</p>
        <p>En cas d'arrêt de travail pour maladie de votre salarié et de maintien de son salaire par l'entreprise, la subrogation de salaire vous permet de percevoir directement les indemnités journalières qui lui sont dues par l'Assurance Maladie. Si c'est le cas, veuillez cocher <b>Oui</b> à la subrogation de salaire et compléter avec les informations suivantes (dates de subrogation, siret du collecteur et rib)</p>
        <p>Quand tout est complété, vous pouvez créer le signalement et ensuite l'envoyer si vous êtes abonné.</p>
        <h4>Signaler une reprise suite à un arrêt de travail</h4>
        <img src="/images/guide/signalement_reprise.jpg" class="w-full" />
        <p>C'est le même principe qu'un signalement d'arrêt de travail (cf: Signaler un arrêt de travail ), on indique le motif de reprise (normal ou temps partiel) ainsi que la date de reprise en plus.</p>
        <p>Quand tout est complété, vous pouvez créer le signalement et ensuite l'envoyer si vous êtes abonné.</p>
        <h4>Signaler une fin de contrat de travail</h4>
        <img src="/images/guide/signalement_fin_contrat_travail.jpg" class="w-full" />
        <p>Le signalement d'une fin de contrat de travail s'effectue en 3 étapes :</p>
        <ul>
        <li>La première étape concerne les informations générales concernant la fin de contrat. Vous devez sélectionner le salarié concerné et le contrat à terminer et saisir les dates correspondantes au motif de fin de contrat. </li>
        <li>La seconde étape consiste à saisir les revenus du dernier mois travaillé.</li>
        <li>La dernière étape permet d'indiquer si un accord transactionnel a été effectué, si une portabilité de la prévoyance est prévue, le statut particulier du salarié et si la fin de contrat inclus un préavis (indiquer le type de préavis si c'est le cas).</li>
        </ul>
        <p>Quand tout est complété, vous pouvez créer le signalement et ensuite l'envoyer si vous êtes abonné.</p>
        `,
  },
  {
    id: 'cibtp',
    title: 'Paramétrage et aide à la saisie - CIBTP',
    content: `<p>Depuis le 1er janvier 2020, les sociétés concernées doivent déclarer leurs bases d'assujettissement auprès de leur Caisse CIBTP via la DSN.</p>
        <p>Vous trouverez ci-après les instructions pour paramétrer votre compte TéléDSN et faire vos déclarations CIBTP en DSN</p>
        <h4>Activer les paramètres CIBTP</h4>
        <img src="/images/guide/cibtp_organismes_sociaux.jpg" class="w-full" />
        <p>La première étape est de sélectionner et d'ajouter la caisse CIBTP dans les organismes sociaux pour votre entreprise</p>
        <img src="/images/guide/cibtp_fiche_salarie.jpg" class="w-full" />
        <p>La dernière étape est d'ajouter sur les contrats de salariés concernés, à l'étape catégorie socio-professionnel, la branche cibtp et la classification métier propre à chaque salarié</p>
        <h4>Aide à la saisie des bases CIBTP dans les DSN Individuelles</h4>
        <img src="/images/guide/cibtp_declaration_individuelle.jpg" class="w-full" />
        <p>Dans la déclaration individuelle d'un salarié, vous déclarez ici les bases spécifiques demandées par votre Caisse CIBTP</p>
        <h4>Aide à la saisie CIBTP dans la DSN entreprise</h4>
        <img src="/images/guide/cibtp_declaration_entreprise.jpg" class="w-full" />
        <p>Dans la déclaration entreprise, les bases de cotisations “établissements” servant à déterminer certaines cotisations CIBTP dont sont redevables les entreprises
        sont à saisir dans ce nouveau bordereau de la DSN Entreprise.</p>
        `,
  },
  {
    id: 'taxe-apprentissage-cpf',
    title: "Paramétrage et aide à la saisie - Taxe d'apprentissage et contributions à la formation professionnelle",
    content: `<p>Depuis le 1er janvier 2022, les sociétés concernées doivent déclarer et payer ces taxes dont le recouvrement est désormais assuré par l’URSSAF via la DSN</p>
        <p>Vous trouverez ci-après les instructions pour paramétrer votre compte TéléDSN et faire vos déclarations</p>
        <h4>Paramétrage général au niveau entreprise</h4>
        <img src="/images/guide/taxe_cpf_fiche_entreprise.jpg" class="w-full" />
        <p>Dans la fiche entreprise, à l'onglet « Fiscalité », si votre convention collective est renseignée et reconnue, cochez les taxes auxquelles vous êtes assujettis</p>
        <p>Si votre convention collective n’est pas reconnue, dans l'onglet « Informations Générales » de la fiche entreprise, précisez via le menu déroulant dédié, l’OPCO (Opérateur de Compétence) auprès duquel vos déclarations
        doivent être faites (l’affichage de ce menu déroulant n’est présent que lorsque la conc. coll. de votre entreprise
        n'est pas reconnue). Vous pourrez ensuite cocher les taxes auxquelles vous êtes assujettis dans l'onglet « Fiscalité »</p>
        <img src="/images/guide/opco.jpg" class="w-full" />
        <h4>La saisie en DSN de la taxe d’apprentissage</h4>
        <h5>Dans les déclarations individuelles :</h5>
        <img src="/images/guide/tax_apprentissage_declaration_indiv.jpg" class="w-full" />
        <p>La ligne de déclaration de la taxe d’apprentissage est automatiquement ajoutée à toutes les DSN individuelles de vos salariés dans la rubrique « Cotisations ». Pour la ligne Taxe d’Apprentissage - part principale : vous devez vérifier et corriger le cas échéant la base
        pré-renseignée</p>
        <p><u>Cas du non-assujettissement</u> : si vous n’êtes pas assujetti à la Taxe d’Apprentissage, vous pouvez supprimer cette
        ligne dans l'une des DSN individuelles de la période courante. Il vous sera alors demandé de retourner sur chacunes d'elles afin de vérifier qu'elle a bien été supprimé partout.</p>
        <p><u>Cas de l’exonération</u> : attention, si vous bénéficiez simplement d’une exonération de Taxe d’Apprentissage, vous
        devez maintenir la cotisation au niveau des DSN individuelles. La déduction s’appliquera au niveau de la DSN
        Entreprise</p>
        <p class="font-italic text-red-500">Nota : si vous y êtes habituellement assujetti, la déclaration de cotisation “part complémentaire” de la Taxe
        d’Apprentissage n’est pas encore déclarable en DSN (vous rapprocher de votre organisme pour plus d’information sur le
        mode de déclaration de cette part complémentaire)</p>
        <h5>Dans la déclaration entreprise :</h5>
        <p>Dans la DSN Entreprise les cotisations de Taxe d’Apprentissage saisies au niveau des DSN individuelles sont
        agrégées dans le Bordereau Urssaf sous l’un des CTP suivants, à sélectionner selon votre situation :</p>
        <ul>
        <li>"Taxe d'Apprentissage - part principale" : <span class='font-bold'>CTP 992 (TAXE APPR. PRINCIPALE REGIME GENERALE)</span></li>
        <li>"Taxe d'Apprentissage - part principale" pour Alsace Moselle : <b>CTP 993 (TAXE APPR. PRINCIPALE ALS.
        MOS.)</b></li>
        </ul>
        <p>Cas de l’exonération : si vous bénéficiez d’une exonération de Taxe d’Apprentissage, la déduction à laquelle
        vous avez le droit doit être saisie dans le CTP 994, en ajoutant la ligne</p>
        <ul>
        <li>"Déduction Taxe d'Apprentissage - part principale" : <b>CTP 994 (TAXE APPR. PRINCIPALE - DEDUCTION)</b></li>
        </ul>
        <h4>La saisie en DSN des contributions Formation Professionnelle</h4>
        <h5>Au niveau des DSN Individuelles</h5>
        <p>Vous devez ajouter la ou les lignes suivantes, selon votre situation :</p>
        <ul>
        <li>Contribution à la Formation Professionnelle (CFP) < 11 salariés</li>
        <li>Contribution à la Formation Professionnelle (CFP) >= 11 salariés</li>
        <li>Contribution au Compte Personnel de Formation (CPF) pour les CDD</li>
        <li>Contribution à la Formation Professionnelle (CFP) Intermittents du Spectacle</li>
        </ul>
        <h5>Au niveau de la DSN Entreprise</h5>
        <p>Vous devez ajouter les CTP suivants, selon votre situation. Les cotisations déclarées correspondent à
        l’agrégation des cotisations renseignées au niveau des DSN individuelles</p>
        <ul>
        <li>Contribution à la Formation Professionnelle (CFP) <11 salariés : <b>CTP 959 (CFP ENTREPRISE < 11 SALARIES)</b></li>
        <li>Contribution à la Formation Professionnelle (CFP) >= 11 salariés : <b>CTP 971 (CFP ENTREPRISE >= 11 SALARIES)</b></li>
        <li>Contribution au Compte Personnel de Formation (CPF) pour les CDD : <b>CTP 987 (CONTRIBUTION CPF CDD)</b></li>
        <li>Contribution à la Formation Professionnelle (CFP) Intermittents du Spectacle : <b>CTP 983 (CFP INTERMITTENTS
        DU SPECTACLE)</b></li>
        </ul>
        `,
  },
]

export default content
