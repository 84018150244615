import axios, { type AxiosInstance } from 'axios'
import { User, ChangeEmailPayload, ChangePasswordPayload } from '@/models/user.model'
import {
  SupportMessagePayload,
  OrgPaymentColumnTab,
  OrgPaymentRowTab,
  SalaryRecap,
  SubscriptionInfoResponse,
  SubscriptionServicePaymentPayload,
  ConfirmPaymentResponse,
  SubscriptionResponse,
  InvoicesResponse,
  CompaniesResponse,
} from '@/models/main.model'
import {
  ObjKeyValueString,
  CitiesSuggestion,
  PreExistingFields,
  RiskCode,
  GeneralInformationsPayload,
  ContactPayload,
  SocialOrganizationPayload,
  TaxationPayload,
  WorkRateAccidentPayload,
  CompanyDetails,
  SocialOrganisationConfigurationSheet,
  EligiblePeriod,
} from '@/models/company.model'
import { Employee, EmployeeContract, RateHistory, EmployeePayload } from '@/models/employee.model'
import {
  AdditionalInformationsPayload,
  CategoriesPayload,
  ContractPayload,
  GeneralInformationsContractPayload,
  StatusSpecificationsPayload,
  WorkingHoursAndWagesPayload,
  ComplementaryOrgMembershipsPayload,
  EndOfContractDetailPayload,
} from '@/models/contract.model'
import {
  EndingContractReason,
  WorkStoppageReportPayload,
  EndingContractReportPayload,
  LastSendingReportDetails,
} from '@/models/reporting.model'
import {
  AbsenceOptions,
  ClassicCtpDetails,
  ClassicCtpDetailsPayload,
  ClassicCtpDetailsResponse,
  CompanyDeclarationCotisation,
  CompanyDeclarationPayload,
  ContratSalarieEligible,
  ContributionPayload,
  CotisationSummary,
  CreateDeclarationPayload,
  Declaration,
  DefaultValuePayload,
  DoethCtpDetails,
  DoethCtpPayload,
  ExemptionPayload,
  GeneralReductionCtpDetails,
  GeneralReductionCtpPayload,
  GeneralReductionCtpSaveResult,
  INDIVIDUAL_DECLARATION_PANEL,
  IndividualDeclarationPayload,
  LearningTaxCtpDetails,
  LearningTaxCtpDetailsPayload,
  MonthlyDeclarationSummary,
  OrganismeEligible,
  RegularizationEmployeeBase,
  RegularizationEmployeeBasePayload,
  RegularizationContractComplementaryOrganism,
  RegularizationEmployeeContribution,
  RegularizationEmployeeContributionPayload,
  RegularizationEmployeeDgfip,
  RegularizationEmployeeDgfipPayload,
  RegularizationEmployeeFranceTravail,
  RegularizationPayload,
  SendingReport,
  ServiceStatus,
  RegularizationGeneralReductionCtpDetails,
  RegularizationGeneralReductionCtpPayload,
  RegularizationClassicCtpDetails,
  RegularizationClassicCtpDetailsPayload,
  UrssafContribution,
  MappingErrorPayload,
} from '@/models/declaration.model'
import { useAuthStore } from '@/stores/auth'
import {
  ExtraHoursOption,
  BonusAndBenefitsInKindOption,
  PaySlipDashboardResponse,
  PaySlipPayload,
  NotSubjectToContributionOption,
  AbsenceTypeOption,
  AmountsRelatedToAbsenceParams,
  ContributionGroup,
  ContributionDefaultValue,
} from '@/models/payslip.model'

export class TELEDSN_API {
  instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: import.meta.env.VITE_TELEDSN_API_URL,
      withCredentials: true,
    })

    /**
     * Gestion du code 401 (Cookie expired/invalid)
     */
    this.instance.interceptors.response.use(
      function (response) {
        return response
      },
      async (error) => {
        if (error.response.status === 401) {
          useAuthStore().showWarningSessionExpired = true
          return Promise.reject(error)
        }
        return Promise.reject(error)
      },
    )
  }

  getUserCookieInfos(): Promise<User> {
    return this.instance.get('/infos/connection').then((res) => {
      return res.data
    })
  }

  changeUserEmail(userId: number, payload: ChangeEmailPayload): Promise<void> {
    return this.instance.put(`/changer/email/valider/${userId}`, payload).then((res) => {
      return res.data
    })
  }

  getUserSubscriptions(userId: string): Promise<SubscriptionResponse[]> {
    return this.instance.get(`/abonnements/${userId}`).then((res) => {
      return res.data
    })
  }

  getUserInvoices(userId: number): Promise<InvoicesResponse[]> {
    return this.instance.get(`/factures/${userId}`).then((res) => {
      return res.data
    })
  }

  getUserCompanies(userId: number, getDeletableStatus: boolean): Promise<CompaniesResponse[]> {
    return this.instance
      .get(`/entreprises/user/${userId}`, {
        params: {
          getDeletableStatus,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  changeUserPassword(userId: number, payload: ChangePasswordPayload): Promise<void> {
    return this.instance.put(`/compte/validerChangementMdp/${userId}`, payload).then((res) => {
      return res.data
    })
  }

  logout(userId: number): Promise<void> {
    return this.instance.get(`/deconnecter/${userId}`).then((res) => {
      return res.data
    })
  }

  getApenCodes(): Promise<ObjKeyValueString[]> {
    return this.instance.get('/v1/reference/code-apen-nafs').then((res) => {
      return res.data
    })
  }

  getCollectiveConventions(): Promise<ObjKeyValueString[]> {
    return this.instance.get('/v1/reference/code-convention-collectives').then((res) => {
      return res.data
    })
  }

  getOpcos(): Promise<ObjKeyValueString[]> {
    return this.instance.get('/v1/reference/opcos').then((res) => {
      return res.data
    })
  }

  getZipCodes(): Promise<string[]> {
    return this.instance.get('/reference/codePostauxFr').then((res) => {
      return res.data
    })
  }

  getCountriesOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get('/v1/reference/pays').then((res) => {
      return res.data
    })
  }

  getNationalitiesOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get('/v1/reference/nationalites').then((res) => {
      return res.data
    })
  }

  getCitiesSuggestions(zipCode: string): Promise<CitiesSuggestion[]> {
    return this.instance.get(`/reference/communes/${zipCode}`).then((res) => {
      return res.data
    })
  }

  getComplementaryPensionReferences(): Promise<ObjKeyValueString[]> {
    return this.instance.get('/v1/reference/retraites-complementaires').then((res) => {
      return res.data
    })
  }

  getCibtpReferences(): Promise<ObjKeyValueString[]> {
    return this.instance.get('/v1/reference/caisse-cibtps').then((res) => {
      return res.data
    })
  }

  getRegimeReferences(): Promise<any> {
    return this.instance.get('/v1/reference/regimes').then((res) => {
      return res.data
    })
  }

  getTaxationReferences(): Promise<ObjKeyValueString[]> {
    return this.instance.get('/v1/reference/fiscalites').then((res) => {
      return res.data
    })
  }

  getWorkAccidentCodeReferences(regime: string): Promise<RiskCode[]> {
    return this.instance
      .get('/v1/reference/code-accident-travail-risques', {
        params: {
          regime,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getPreExistingFields(siret: string): Promise<PreExistingFields> {
    return this.instance
      .get('/recherche-infos-insee/entreprise', {
        params: {
          siret,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  generateTemporarySocialNumber(companyId: number, civility: string): Promise<{ numeroNtt: string }> {
    return this.instance
      .get(`/v1/entreprise/${companyId}/generer/numero-ntt-salarie`, {
        params: {
          civilite: civility,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  createNewCompany(payload: GeneralInformationsPayload): Promise<{ id: number }> {
    return this.instance.post('/v1/entreprise', payload).then((res) => {
      return res.data
    })
  }

  modifyCompanyGeneralInformation(companyId: number, payload: GeneralInformationsPayload): Promise<void> {
    return this.instance.put(`/v1/entreprise/${companyId}/informations-generales`, payload).then((res) => {
      return res.data
    })
  }

  modifyCompanyContact(companyId: number, payload: ContactPayload): Promise<void> {
    return this.instance.put(`/v1/entreprise/${companyId}/contact`, payload).then((res) => {
      return res.data
    })
  }

  modifyCompanySocialOrganization(companyId: number, payload: SocialOrganizationPayload): Promise<void> {
    return this.instance.put(`/v1/entreprise/${companyId}/organismes-sociaux`, payload).then((res) => {
      return res.data
    })
  }

  modifyTaxationDetails(companyId: number, payload: TaxationPayload): Promise<void> {
    return this.instance.put(`/v1/entreprise/${companyId}/fiscalite`, payload).then((res) => {
      return res.data
    })
  }

  modifyAccidentWorkRateDetails(companyId: number, payload: WorkRateAccidentPayload): Promise<void> {
    return this.instance.put(`/v1/entreprise/${companyId}/accident-travail`, payload).then((res) => {
      return res.data
    })
  }

  getCompanyDetails(companyId: number): Promise<CompanyDetails> {
    return this.instance.get(`/v1/entreprise/${companyId}`).then((res) => {
      return res.data
    })
  }

  getCompanyEmployees(companyId: number): Promise<Employee[]> {
    return this.instance.get(`/v1/entreprise/${companyId}/salaries`).then((res) => {
      return res.data
    })
  }

  getEmployeeContracts(employeeId: number): Promise<EmployeeContract[]> {
    return this.instance.get(`/v1/salarie/${employeeId}/contrats`).then((res) => {
      return res.data
    })
  }

  getEmployeeRateHistory(employeeId: number): Promise<RateHistory[]> {
    return this.instance.get(`/v1/salarie/${employeeId}/taux-pas`).then((res) => {
      return res.data
    })
  }

  getSocialOrganizationConfigurationSheet(companyId: number): Promise<SocialOrganisationConfigurationSheet> {
    return this.instance.get(`/v1/entreprise/${companyId}/fiches-parametrages-oc`).then((res) => {
      return res.data
    })
  }

  transmitSocialOrganizationConfigurationSheet(companyId: number, sheet: File): Promise<void> {
    return this.instance
      .post(`/v1/entreprise/${companyId}/fiches-parametrages-oc`, sheet, {
        headers: {
          'Content-Type': sheet.type,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  deleteCompany(companyId: number): Promise<void> {
    return this.instance.delete(`/entreprises/${companyId}`).then((res) => {
      return res.data
    })
  }

  addEmployeeToCompany(companyId: number, payload: EmployeePayload): Promise<{ id: number }> {
    return this.instance.post(`/v1/entreprise/${companyId}/salarie`, payload).then((res) => {
      return res.data
    })
  }

  getEmployeeInfos(employeeId: number): Promise<EmployeePayload> {
    return this.instance.get(`/v1/salarie/${employeeId}`).then((res) => {
      return res.data
    })
  }

  modifyEmployeeInfos(employeeId: number, payload: EmployeePayload): Promise<void> {
    return this.instance.put(`/v1/salarie/${employeeId}`, payload).then((res) => {
      return res.data
    })
  }

  deleteEmployee(employeeId: number): Promise<void> {
    return this.instance.delete(`v1/salarie/${employeeId}`).then((res) => {
      return res.data
    })
  }

  getContractTypes(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/natures`).then((res) => {
      return res.data
    })
  }

  getReasonOptionsForContract(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/motifs-recours`).then((res) => {
      return res.data
    })
  }

  getPublicAssistancePolicies(contractType?: string | null): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/contrat/dispositifs-politiques`, { params: { natureContrat: contractType } })
      .then((res) => {
        return res.data
      })
  }

  getDiplomasOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/diplomes`).then((res) => {
      return res.data
    })
  }

  getCasualEntertainmentWorkerTypes(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/types-intermittents-spectacles`).then((res) => {
      return res.data
    })
  }

  createNewContract(
    employeeId: number,
    payload: GeneralInformationsContractPayload,
  ): Promise<{ id: number; actif: boolean; numero: string }> {
    return this.instance.post(`v1/salarie/${employeeId}/contrat`, payload).then((res) => {
      return res.data
    })
  }

  modifyContractGeneralInformations(contractId: number, payload: GeneralInformationsContractPayload): Promise<void> {
    return this.instance.put(`/v1/contrat/${contractId}/informations-generales`, payload).then((res) => {
      return res.data
    })
  }

  modifyContractStatusOptions(contractId: number, payload: StatusSpecificationsPayload): Promise<void> {
    return this.instance.put(`/v1/contrat/${contractId}/status`, payload).then((res) => {
      return res.data
    })
  }

  getFamillesPcsEse(casualEntertainmentWorkerType: string | null): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/contrat/familles-pcs-ese`, {
        params: { typeIntermittentSpectacle: casualEntertainmentWorkerType },
      })
      .then((res) => {
        return res.data
      })
  }

  getCategoriesPcsEse(
    groupeCode: string,
    publicAssistancePolicy: string | null,
    casualEntertainmentWorkerType: string | null,
  ): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/contrat/listes-pcs-ese`, {
        params: {
          codeFamille: groupeCode,
          dispositifPolitique: publicAssistancePolicy,
          typeIntermittentSpectacle: casualEntertainmentWorkerType,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  // eslint-disable-next-line max-params
  getComplementsPcsEse(
    companyId: number,
    pcsEseKey: string,
    casualEntertainmentWorkerType: string | null,
    section: string | null,
  ): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/contrat/complements-pcs-ese`, {
        params: {
          entrepriseId: companyId,
          codePcsEse: pcsEseKey,
          typeIntermittentSpectacle: casualEntertainmentWorkerType,
          branche: section,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getBranchesCiBTP(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/branches`).then((res) => {
      return res.data
    })
  }

  // eslint-disable-next-line max-params
  getClassificationMetiersCiBTP(
    companyId: number,
    pcsEseKey: string | null,
    section: string | null,
    complementPcsEseKey: string | null,
  ): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/contrat/classifications-metiers-cibtps`, {
        params: {
          entrepriseId: companyId,
          codePcsEse: pcsEseKey,
          branche: section,
          complementPcsEse: complementPcsEseKey,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getRetraiteComplementaireParPcsEse(
    pcsEseKey: string | null,
    complementPcsEseKey: string | null,
    casualEntertainmentWorkerType: string | null,
  ): Promise<{ retraite: string }> {
    return this.instance
      .get(`/v1/reference/contrat/retraite-complementaire-par-pcs-ese`, {
        params: {
          codePcsEse: pcsEseKey,
          complementPcsEse: complementPcsEseKey,
          typeIntermittentSpectacle: casualEntertainmentWorkerType,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  modifyContractCategory(contractId: number, payload: CategoriesPayload): Promise<void> {
    return this.instance.put(`/v1/contrat/${contractId}/categorie-socio-professionelle`, payload).then((res) => {
      return res.data
    })
  }

  modifyContractWorkingHoursAndWages(contractId: number, payload: WorkingHoursAndWagesPayload): Promise<void> {
    return this.instance.put(`/v1/contrat/${contractId}/temps-travail-et-salaire`, payload).then((res) => {
      return res.data
    })
  }

  getWorkingUnitOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/unites-travail`).then((res) => {
      return res.data
    })
  }

  getWorkingTimeModeOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/modalites-temps`).then((res) => {
      return res.data
    })
  }

  getProfessionalExpenseRates(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/taux-frais-professionnels`).then((res) => {
      return res.data
    })
  }

  getDisabledWorkers(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/travailleurs-handicapes`).then((res) => {
      return res.data
    })
  }

  getArduousExposures(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/expositions-penibilites`).then((res) => {
      return res.data
    })
  }

  getRiskFactors(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/facteurs-risques-penibilites`).then((res) => {
      return res.data
    })
  }

  getWorkerAbroads(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/contrat/salaries-detaches-expatries`).then((res) => {
      return res.data
    })
  }

  getContractWorkAccidentRates(contractId: number): Promise<
    {
      ordreTauxAt: number
      taux: number
    }[]
  > {
    return this.instance.get(`/v1/reference/contrat/${contractId}/taux-at-possibles`).then((res) => {
      return res.data
    })
  }

  modifyContractAdditionalInformations(contractId: number, payload: AdditionalInformationsPayload): Promise<void> {
    return this.instance.put(`/v1/contrat/${contractId}/informations-complementaires`, payload).then((res) => {
      return res.data
    })
  }

  getContract(contractId: number): Promise<ContractPayload> {
    return this.instance.get(`/v1/contrat/${contractId}`).then((res) => {
      return res.data
    })
  }

  deleteContract(contractId: number): Promise<void> {
    return this.instance.delete(`v1/contrat/${contractId}`).then((res) => {
      return res.data
    })
  }

  modifyContractComplementaryOrgMemberships(
    contractId: number,
    payload: ComplementaryOrgMembershipsPayload[],
  ): Promise<void> {
    return this.instance
      .put(`/v1/contrat/${contractId}/affiliations-organismes-complementaires`, payload)
      .then((res) => {
        return res.data
      })
  }

  sendSupportMessage(payload: SupportMessagePayload): Promise<{ message: string }> {
    return this.instance.post(`v1/demande/contact`, payload).then((res) => {
      return res.data
    })
  }

  getDeclarations(companyId: number, onlyReports?: boolean): Promise<Declaration[]> {
    return this.instance
      .get(`/v1/entreprise/${companyId}/declarations`, { params: { signalement: onlyReports } })
      .then((res) => {
        return res.data
      })
  }

  getReportSendingDeclaration(declarationId: number, nature: string): Promise<SendingReport> {
    return this.instance
      .get(`/v1/declarations/${declarationId}/rapports-envoi`, {
        params: {
          nature,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getDsnFile(declarationId: number, nature: string): Promise<Blob> {
    return this.instance
      .get(`/v1/declarations/${declarationId}/fichier-dsn`, {
        params: {
          nature,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getPaySlipPdf(individualDeclarationId: number): Promise<string> {
    return this.instance
      .get(`/${individualDeclarationId}/paye/pdf`, { baseURL: import.meta.env.VITE_TELEDSN_BACKEND_URL })
      .then((res) => {
        return res.data
      })
  }

  getMonthlyDeclarationSummary(declarationId: number): Promise<MonthlyDeclarationSummary> {
    return this.instance.get(`/v1/declarations/mensuelle/${declarationId}/resume`).then((res) => {
      return res.data
    })
  }

  getEndingContractReasons(contractId: number): Promise<EndingContractReason[]> {
    return this.instance.get(`/v1/contrats/${contractId}/motif-arrets`).then((res) => {
      return res.data
    })
  }

  getWorkingStoppageReasonOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`v1/reference/motifs-arret-travail`).then((res) => {
      return res.data
    })
  }

  getResumingOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/motifs-reprise-arret-travail`).then((res) => {
      return res.data
    })
  }

  reportWorkStoppage(payload: WorkStoppageReportPayload): Promise<void> {
    return this.instance.post(`/v1/declarations/arret-reprise-travail`, payload).then((res) => {
      return res.data
    })
  }

  deleteReportWorkStoppage(reportId: number): Promise<void> {
    return this.instance.delete(`/v1/declarations/arret-reprise-travail/${reportId}`).then((res) => {
      return res.data
    })
  }

  getReportWorkStoppageInfos(reportId: number): Promise<{
    informationsGenerales: WorkStoppageReportPayload
    detailDernierEnvoi: LastSendingReportDetails
  }> {
    return this.instance.get(`/v1/declarations/arret-reprise-travail/${reportId}`).then((res) => {
      return res.data
    })
  }

  modifyReportWorkStoppageInfos(reportId: number, payload: WorkStoppageReportPayload): Promise<void> {
    return this.instance.put(`/v1/declarations/arret-reprise-travail/${reportId}`, payload).then((res) => {
      return res.data
    })
  }

  getReportEndingContractInfos(reportId: number): Promise<{
    informationsGenerales: EndingContractReportPayload
    detailDernierEnvoi: LastSendingReportDetails
  }> {
    return this.instance.get(`/v1/declarations/fin-contrat-travail/${reportId}`).then((res) => {
      return res.data
    })
  }

  modifyReportEndingContract(payload: EndingContractReportPayload, reportId: number): Promise<void> {
    return this.instance.put(`/v1/declarations/fin-contrat-travail/${reportId}`, payload).then((res) => {
      return res.data
    })
  }

  deleteReportEndingContract(reportId: number): Promise<void> {
    return this.instance.delete(`/v1/declarations/fin-contrat-travail/${reportId}`).then((res) => {
      return res.data
    })
  }

  deleteDeclaration(declarationId: number): Promise<void> {
    return this.instance.delete(`/v1/declarations/mensuelle/${declarationId}`).then((res) => {
      return res.data
    })
  }

  resetRegularization(period: string, companyDeclarationId: number): Promise<RegularizationPayload> {
    return this.instance
      .put(`v1/declarations/entreprise/${companyDeclarationId}/regularisation/reinitialiser`, {
        periodeARegulariser: period,
      })
      .then((res) => {
        return res.data
      })
  }

  deleteRegularization(period: string, companyDeclarationId: number): Promise<void> {
    return this.instance
      .delete(`v1/declarations/entreprise/${companyDeclarationId}/regularisation`, {
        params: {
          periodeARegulariser: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getNoticeOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/motifs-preavis`).then((res) => {
      return res.data
    })
  }

  getSpecialStatusOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/statut-particulier-salarie`).then((res) => {
      return res.data
    })
  }

  checkAnticipatedDsnPresence(
    endDateContract: string,
    contractId: number,
  ): Promise<{ dejaEnvoyeDsnMensuelleAnticipee: boolean; doitFaireProcedureFranceTravail: boolean }> {
    return this.instance
      .get(`/v1/contrat/${contractId}/presence-dsn-mensuelle-anticipee`, {
        params: {
          finContrat: endDateContract,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getHourlyWagesAndDaysWorkedAndComplementaryInputs(
    contractId: number,
    lastDayWork: Date,
    endingReason: string,
  ): Promise<{ messageSalaireHoraireNet: string; salaireHoraireNet: number; champsComplementaires: string[] }> {
    return this.instance
      .get(`/v1/declarations/fin-contrat-travail/champs-complementaires`, {
        params: {
          cId: contractId,
          motifFin: endingReason,
          dernierJourTravaille: lastDayWork,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  reportEndingContract(payload: EndingContractReportPayload): Promise<void> {
    return this.instance.post(`/v1/declarations/fin-contrat-travail`, payload).then((res) => {
      return res.data
    })
  }

  getExtraHourOptions(contractId: number | string, period: string): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/declaration/heures-supplementaires`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getOtherIncomeOptions(contractId: number, period: string): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/declaration/autres-revenus`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getContributionOptions(contractId: number, period: string): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/declaration/cotisations`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getExemptionOptions(contractId: number, period: string): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/declaration/exonerations`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getBonusOptions(contractId: number | string, period: string): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/declaration/primes`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getCompensationOptions(contractId: number | string, period: string): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/declaration/indemnites`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getWorkingTimeUnitOptions(contractId: number | string, period: string): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/declaration/unites-temps-travail`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getAbsenceOptions(contractId: string | number, period: string): Promise<AbsenceOptions[]> {
    return this.instance
      .get(`/v1/reference/declaration/absences`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationAbsenceOptions(contractId: string | number, period: string): Promise<AbsenceOptions[]> {
    return this.instance
      .get(`/v1/reference/declaration/absences/regularisation`, {
        params: {
          cId: contractId,
          periode: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getIndividualDeclaration(individualDeclarationId: number): Promise<IndividualDeclarationPayload> {
    return this.instance.get(`/v1/declarations/individuelle/${individualDeclarationId}`).then((res) => {
      return res.data
    })
  }

  getBaseOptions(): Promise<ObjKeyValueString[]> {
    return this.instance.get(`/v1/reference/declaration/bases-assujetties`).then((res) => {
      return res.data
    })
  }

  uploadCrmFile(file: File, companyId: number): Promise<void> {
    return this.instance.post(`/v1/entreprise/${companyId}/charger-crm-taux-pas `, file).then((res) => {
      return res.data
    })
  }

  getEligiblePeriods(companyId: number): Promise<EligiblePeriod[]> {
    return this.instance.get(`/v1/entreprise/${companyId}/periodes-eligibles`).then((res) => {
      return res.data
    })
  }

  createMonthlyDeclaration(payload: CreateDeclarationPayload): Promise<{ id: number }> {
    return this.instance.post(`/v1/declarations/mensuelle`, payload).then((res) => {
      return res.data
    })
  }

  putIndividualDeclaration(
    payload: IndividualDeclarationPayload,
    params: { panel?: INDIVIDUAL_DECLARATION_PANEL; persistence: boolean; individualDeclarationId: number },
  ): Promise<{
    detail: IndividualDeclarationPayload
    mapWarningsByDisplayBlock: Record<INDIVIDUAL_DECLARATION_PANEL, MappingErrorPayload>
  }> {
    return this.instance
      .put(`/v1/declarations/individuelle/${params.individualDeclarationId}`, payload, {
        params: {
          persistence: params.persistence,
          blocSpecifiqueErreur: params.panel,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getDefaultValueForAContribution(
    declarationId: number,
    contributionCode: string,
    payload: DefaultValuePayload,
  ): Promise<ContributionPayload> {
    return this.instance
      .post(`/v1/declarations/individuelle/${declarationId}/cotisation`, payload, {
        params: { code: contributionCode },
      })
      .then((res) => {
        return res.data
      })
  }

  getDefaultValueForAExemption(
    declarationId: number,
    exemptionCode: string,
    payload: DefaultValuePayload,
  ): Promise<ExemptionPayload> {
    return this.instance
      .post(`/v1/declarations/individuelle/${declarationId}/exoneration`, payload, { params: { code: exemptionCode } })
      .then((res) => {
        return res.data
      })
  }

  getOrgPaymentsRecap(
    year: number,
    companyId: number,
  ): Promise<{ columns: OrgPaymentColumnTab[]; rows: OrgPaymentRowTab[] }> {
    return this.instance
      .get(`/v1/recapitulatif/paiement/${companyId}`, {
        params: {
          year,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getSalariesRecap(year: number, companyId: number): Promise<SalaryRecap[]> {
    return this.instance
      .get(`/v1/recapitulatif/salaires/${companyId}`, {
        params: {
          year,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getCompanyDeclaration(companyDeclarationId: number): Promise<CompanyDeclarationPayload> {
    return this.instance.get(`/v1/declarations/entreprise/${companyDeclarationId}`).then((res) => {
      return res.data
    })
  }

  saveCompanyDeclaration(companyDeclarationId: number, payload: CompanyDeclarationPayload): Promise<void> {
    return this.instance.put(`/v1/declarations/entreprise/${companyDeclarationId}`, payload).then((res) => {
      return res.data
    })
  }

  getUrssafContributionOptions(companyDeclarationId: number): Promise<ObjKeyValueString[]> {
    return this.instance
      .get(`/v1/reference/declaration/cotisations-urssaf`, {
        params: {
          deId: companyDeclarationId,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  resetIndividualDeclaration(
    individualDeclarationId: number,
  ): Promise<{ detail: IndividualDeclarationPayload; messages: [] }> {
    return this.instance.put(`/v1/declarations/individuelle/${individualDeclarationId}/reinitialiser`).then((res) => {
      return res.data
    })
  }

  resetCompanyDeclaration(companyDeclarationId: number): Promise<CompanyDeclarationPayload> {
    return this.instance.get(`/v1/declarations/entreprise/${companyDeclarationId}/reset`).then((res) => {
      return res.data
    })
  }

  getCotisationsSummary(declarationId: number): Promise<{
    syntheses: CotisationSummary[]
    messagesErreursEnvois: string[]
    desactivationEnvois: ServiceStatus[]
  }> {
    return this.instance.get(`/v1/declarations/mensuelle/${declarationId}/synthese-avant-envoi`).then((res) => {
      return res.data
    })
  }

  getDoethCtpDetails(
    companyDeclarationId: number,
    params: {
      ctpCode: string
      isRegularization?: boolean
      period: string | null
    },
  ): Promise<DoethCtpDetails> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-doeth`, {
        params: {
          code: params.ctpCode,
          isRegularisation: params.isRegularization,
          periodeRegularisation: params.period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  saveDoethCtp(
    companyDeclarationId: number,
    payload: DoethCtpPayload[],
    params?: {
      isRegularization?: boolean
      period: string | null
    },
  ): Promise<ClassicCtpDetailsResponse> {
    return this.instance
      .post(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-doeth`, payload, {
        params: {
          isRegularisation: params?.isRegularization,
          periodeRegularisation: params?.period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getGeneralReductionCtpDetails(companyDeclarationId: number, ctpCode: string): Promise<GeneralReductionCtpDetails> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-reduction-generale`, {
        params: {
          code: ctpCode,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationGeneralReductionCtpDetails(
    companyDeclarationId: number,
    ctpCode: string,
    period: string,
  ): Promise<RegularizationGeneralReductionCtpDetails[]> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/detail-ctp-reduction-generale`, {
        params: {
          ctp: ctpCode,
          periodeRegularisation: period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  recalculateGeneralReductionExemption(params: {
    individualDeclarationId: number
    code: string
    amount: number
  }): Promise<{ reductionUrssaf: number; reductionRetraite: number }> {
    return this.instance
      .get(`/v1/declarations/individuelle/${params.individualDeclarationId}/reduction-generale/recalcul`, {
        params: {
          codeExoneration: params.code,
          montantExoneration: params.amount,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  recalculateGeneralReductionCtp(
    companyDeclarationId: number,
    params: { individualDeclarationId: number; amount: number; code: string },
  ): Promise<{ reductionUrssaf: number; reductionRetraite: number }> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-reduction-generale/recalcul`, {
        params: {
          dsnId: params.individualDeclarationId,
          codeExoneration: params.code,
          montantExoneration: params.amount,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  saveGeneralReductionCtp(
    companyDeclarationId: number,
    payload: GeneralReductionCtpPayload[],
  ): Promise<GeneralReductionCtpSaveResult> {
    return this.instance
      .post(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-reduction-generale`, payload)
      .then((res) => {
        return res.data
      })
  }

  saveRegularizationGeneralReductionCtp(
    companyDeclarationId: number,
    payload: RegularizationGeneralReductionCtpPayload[],
    params: {
      ctpCode: string
      period: string
    },
  ): Promise<CompanyDeclarationCotisation> {
    return this.instance
      .post(
        `/v1/declarations/entreprise/${companyDeclarationId}/regularisation/detail-ctp-reduction-generale`,
        payload,
        {
          params: {
            ctp: params.ctpCode,
            periodeRegularisation: params.period,
          },
        },
      )
      .then((res) => {
        return res.data
      })
  }

  getLearningTaxCtpDetails(
    companyDeclarationId: number,
    params: {
      ctpCode: string
      isRegularization?: boolean
      period: string | null
    },
  ): Promise<LearningTaxCtpDetails> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-taxe-apprentissage`, {
        params: {
          code: params.ctpCode,
          isRegularisation: params.isRegularization,
          periodeRegularisation: params.period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  saveLearningTaxCtp(
    companyDeclarationId: number,
    payload: LearningTaxCtpDetailsPayload,
    params: {
      isRegularization?: boolean
      period: string | null
    },
  ): Promise<ClassicCtpDetailsResponse> {
    return this.instance
      .post(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-taxe-apprentissage`, payload, {
        params: {
          isRegularisation: params.isRegularization,
          periodeRegularisation: params.period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getClassicCtpDetails(companyDeclarationId: number, ctpCode: string): Promise<ClassicCtpDetails> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-classique`, {
        params: {
          code: ctpCode,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  saveClassicCtpDetails(
    companyDeclarationId: number,
    payload: ClassicCtpDetailsPayload,
  ): Promise<ClassicCtpDetailsResponse> {
    return this.instance
      .post(`/v1/declarations/entreprise/${companyDeclarationId}/detail-ctp-classique`, payload)
      .then((res) => {
        return res.data
      })
  }

  getRegularizationClassicCtpDetails(
    companyDeclarationId: number,
    params: {
      ctpCode: string
      period: string
    },
  ): Promise<RegularizationClassicCtpDetails> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/detail-ctp-classique`, {
        params: {
          ctp: params.ctpCode,
          periodeRegularisation: params.period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  saveRegularizationClassicCtpDetails(
    companyDeclarationId: number,
    payload: RegularizationClassicCtpDetailsPayload[],
    params: {
      ctpCode: string
      period: string
    },
  ): Promise<ClassicCtpDetailsResponse> {
    return this.instance
      .post(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/detail-ctp-classique`, payload, {
        params: {
          ctp: params.ctpCode,
          periodeRegularisation: params.period,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getDefaultValueForUrssafContribution(
    companyDeclarationId: number,
    ctp: {
      code: string
      qualifiant: string
      codeInsee: string
    },
    regularization: { isRegularization: boolean; period: string },
  ): Promise<CompanyDeclarationCotisation[]> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/ctp`, {
        params: {
          code: ctp.code,
          regularisation: regularization.isRegularization,
          periodeARegulariser: regularization.period,
          qualifiant: ctp.qualifiant,
          codeInsee: ctp.codeInsee,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationUrssafContributionOptions(
    period: string,
    companyDeclarationId: number,
  ): Promise<UrssafContribution[]> {
    return this.instance
      .get(`/v1/reference/declaration/cotisation-urssaf/regularisation`, {
        params: { deId: companyDeclarationId, periodeARegulariser: period },
      })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationEligibleContracts(companyDeclarationId: number, period: string): Promise<ContratSalarieEligible[]> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/contrats-salaries-elligibles`, {
        params: { periodeARegulariser: period },
      })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationEligibleOrganizations(companyDeclarationId: number, period: string): Promise<OrganismeEligible[]> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/organismes-elligibles`, {
        params: { periodeARegulariser: period },
      })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationDefaultUrssafContributions(
    companyDeclarationId: number,
    period: string,
  ): Promise<CompanyDeclarationCotisation[]> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/cotisation-urssaf-par-defaut`, {
        params: { periodeARegulariser: period },
      })
      .then((res) => {
        return res.data
      })
  }

  getSubscriptionInfos(companyId: number): Promise<SubscriptionInfoResponse> {
    return this.instance.get(`/v1/abonnement/${companyId}`).then((res) => {
      return res.data
    })
  }

  getLastSentCrmFile(companyId: number): Promise<{ fileName: string | null; data: BlobPart }> {
    return this.instance
      .get(`/v1/entreprise/${companyId}/telecharger-dernier-crm-taux-pas`, { responseType: 'blob' })
      .then((res) => {
        const match = res.headers['content-disposition']?.match(/filename\*?=(?:UTF-8'')?([^;]+)/i)
        const fileName = match ? decodeURIComponent(match[1].replace(/['"]/g, '')) : null
        return { fileName, data: res.data }
      })
  }

  confirmPayment(
    companyId: number,
    payload: SubscriptionServicePaymentPayload,
    params: {
      declarationId: number | null
      nature: string
    },
  ): Promise<ConfirmPaymentResponse> {
    return this.instance
      .post(`/v1/abonnement/${companyId}`, payload, { params: { deId: params.declarationId, nature: params.nature } })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationEmployeeContributions(
    companyDeclarationId: number,
    params: {
      period: string
      contractId: string
      docketIdentify: string
    },
  ): Promise<RegularizationEmployeeContribution> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/retraite`, {
        params: {
          periodeARegulariser: params.period,
          contratId: params.contractId,
          identifiant: params.docketIdentify,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  putRegularizationEmployeeContributions(
    companyDeclarationId: number,
    payload: RegularizationEmployeeContributionPayload,
  ): Promise<{ contratId: number; regularisation: number }> {
    return this.instance
      .put(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/retraite`, payload)
      .then((res) => {
        return res.data
      })
  }

  getRegularizationEmployeeBases(
    companyDeclarationId: number,
    period: string,
    contractId: string,
  ): Promise<RegularizationEmployeeBase[]> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/bases-assujetties`, {
        params: { periodeARegulariser: period, contratId: contractId },
      })
      .then((res) => {
        return res.data
      })
  }

  putRegularizationEmployeeBases(
    companyDeclarationId: number,
    payload: RegularizationEmployeeBasePayload,
  ): Promise<void> {
    return this.instance
      .put(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/bases-assujetties`, payload)
      .then((res) => {
        return res.data
      })
  }

  getRegularizationContractsComplementaryOrganism(
    companyDeclarationId: number,
    params: { period: string; contractId?: string; organismCode: string },
  ): Promise<RegularizationContractComplementaryOrganism[]> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/organismes-complementaires`, {
        params: {
          periodeARegulariser: params.period,
          contratId: params.contractId,
          codeOrganisme: params.organismCode,
        },
      })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationEmployeeFranceTravail(
    companyDeclarationId: number,
    period: string,
    contractId: string,
  ): Promise<RegularizationEmployeeFranceTravail[]> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/france-travail`, {
        params: { periodeARegulariser: period, contratId: contractId },
      })
      .then((res) => {
        return res.data
      })
  }

  getRegularizationEmployeeDgfip(
    companyDeclarationId: number,
    period: string,
    contractId: string,
  ): Promise<RegularizationEmployeeDgfip> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/dgfip`, {
        params: { periodeARegulariser: period, contratId: contractId },
      })
      .then((res) => {
        return res.data
      })
  }

  putRegularizationEmployeeDgfip(
    companyDeclarationId: number,
    payload: RegularizationEmployeeDgfipPayload,
  ): Promise<{ contratId: number; montantPas: number }> {
    return this.instance
      .put(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation/dgfip`, payload)
      .then((res) => {
        return res.data
      })
  }

  getRegularization(
    companyDeclarationId: number,
    period: string,
    initializing: boolean,
  ): Promise<RegularizationPayload> {
    return this.instance
      .get(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation`, {
        params: { periodeARegulariser: period, initCreation: initializing },
      })
      .then((res) => {
        return res.data
      })
  }

  putRegularization(companyDeclarationId: number, payload: RegularizationPayload): Promise<void> {
    return this.instance
      .put(`/v1/declarations/entreprise/${companyDeclarationId}/regularisation`, payload)
      .then((res) => {
        return res.data
      })
  }

  sendWorkingStoppageReport(reportId: number): Promise<void> {
    return this.instance.post(`/v1/declarations/arret-reprise-travail/${reportId}/envoyer`).then((res) => {
      return res.data
    })
  }

  sendEndingContractReport(reportId: number): Promise<void> {
    return this.instance.post(`/v1/declarations/fin-contrat-travail/${reportId}/envoyer`).then((res) => {
      return res.data
    })
  }

  sendDeclaration(declarationId: number): Promise<void> {
    return this.instance.post(`/v1/declarations/mensuelle/${declarationId}/envoyer`).then((res) => {
      return res.data
    })
  }

  getPreviousTauxPAS(individualDeclarationId: number): Promise<{ tauxPas: number }> {
    return this.instance
      .get(`/v1/declarations/individuelle/${individualDeclarationId}/taux-mois-precedent`)
      .then((res) => {
        return res.data
      })
  }

  getLocalityFromInsee(codeInsee: string): Promise<CitiesSuggestion> {
    return this.instance.get(`/reference/commune/${codeInsee}`).then((res) => {
      return res.data
    })
  }

  displayOnBoardingTour(): Promise<{ result: boolean }> {
    return this.instance.get('/v1/afficher-product-tour').then((res) => {
      return res.data
    })
  }

  recordLastStepOnBoardingTour(step: string): Promise<void> {
    return this.instance.post(`/v1/update-product-tour/${step}`).then((res) => {
      return res.data
    })
  }

  reopenDeclaration(declarationId: number): Promise<void> {
    return this.instance.put(`/v1/declarations/mensuelle/${declarationId}/ouvrir`).then((res) => {
      return res.data
    })
  }

  saveEndOfContractDetail(contractId: number, payload: EndOfContractDetailPayload) {
    return this.instance.put(`/v1/contrat/${contractId}/detail-fin-cdd`, payload).then((res) => {
      return res.data
    })
  }

  setDeclarationInPendingPayment(declarationId: number, nature: string): Promise<void> {
    return this.instance
      .put(`/v1/declarations/${declarationId}/remettre-attente-paiement`, null, { params: { nature } })
      .then((res) => {
        return res.data
      })
  }

  getPaySlips(companyId: number, period?: string): Promise<PaySlipDashboardResponse[]> {
    return this.instance
      .get(`/v1/entreprise/${companyId}/bulletins-paie`, { params: { periode: period } })
      .then((res) => {
        return res.data
      })
  }

  createPaySlip(companyId: number, period: string, contractId?: number): Promise<number[]> {
    return this.instance
      .post(`/v1/entreprise/${companyId}/bulletins-paie`, null, { params: { periode: period, contratId: contractId } })
      .then((res) => {
        return res.data
      })
  }

  getPaySlip(paySlipId: number): Promise<PaySlipPayload> {
    return this.instance.get(`/v1/bulletins-paie/${paySlipId}`).then((res) => {
      return res.data
    })
  }

  savePaySlip(paySlipId: number, payload: PaySlipPayload): Promise<void> {
    return this.instance.put(`/v1/bulletins-paie/${paySlipId}`, payload).then((res) => {
      return res.data
    })
  }

  getPaySlipExtraHoursReferences(paySlipId: number): Promise<ExtraHoursOption[]> {
    return this.instance.get(`/v1/reference/paie/${paySlipId}/heures-supplementaires`).then((res) => {
      return res.data
    })
  }

  getPaySlipExceptionalHoursReferences(paySlipId: number): Promise<ExtraHoursOption[]> {
    return this.instance.get(`/v1/reference/paie/${paySlipId}/heures-complementaires`).then((res) => {
      return res.data
    })
  }

  getPaySlipBonusReferences(paySlipId: number): Promise<BonusAndBenefitsInKindOption[]> {
    return this.instance.get(`/v1/reference/paie/${paySlipId}/primes`).then((res) => {
      return res.data
    })
  }

  getPaySlipBenefitInKindReferences(paySlipId: number): Promise<BonusAndBenefitsInKindOption[]> {
    return this.instance.get(`/v1/reference/paie/${paySlipId}/avantages-en-nature`).then((res) => {
      return res.data
    })
  }

  getPaySlipNotSubjectToContributionsReferences(paySlipId: number): Promise<NotSubjectToContributionOption[]> {
    return this.instance.get(`/v1/reference/paie/${paySlipId}/elements-non-soumis-cotisations`).then((res) => {
      return res.data
    })
  }

  getPaySlipAbsenceTypesReferences(paySlipId: number): Promise<AbsenceTypeOption[]> {
    return this.instance.get(`/v1/reference/paie/${paySlipId}/type-absences`).then((res) => {
      return res.data
    })
  }

  getPaySlipPublicHolidaysReferences(period: string): Promise<string[]> {
    return this.instance.get(`/v1/reference/paie/jours-feries`, { params: { periode: period } }).then((res) => {
      return res.data
    })
  }

  getPaySlipContributionsReferences(period: string, contractId: number): Promise<ContributionGroup[]> {
    return this.instance
      .get(`/v1/reference/paie/cotisations`, { params: { periode: period, cId: contractId } })
      .then((res) => {
        return res.data
      })
  }

  getPaySlipDefaultValueForAContribution(paySlipId: number, code: string): Promise<ContributionDefaultValue> {
    return this.instance.get(`/v1/bulletins-paie/${paySlipId}/cotisations`, { params: { code } }).then((res) => {
      return res.data
    })
  }

  getPaySlipAmountsRelatedToAbsence(
    paySlipId: number,
    params: AmountsRelatedToAbsenceParams,
  ): Promise<{ montantIndemnite: number; montantRetenue: number }> {
    return this.instance
      .get(`/v1/bulletins-paie/${paySlipId}/absences/montants`, {
        params: { code: params.code, nbJours: params.days.toString(), nbHeures: params.hours },
      })
      .then((res) => {
        return res.data
      })
  }
}

const api = new TELEDSN_API()

export const teledsnAPI: TELEDSN_API = api
