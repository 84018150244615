<script setup lang="ts">
import { ref } from 'vue'
import { Router } from '@/models/router.model'
import { useRoute } from 'vue-router'
import InterfaceSettings from '../commons/InterfaceSettings.vue'

const route = useRoute()

const showSettingDialog = ref(false)
const items = ref([
  {
    label: 'Déclarations',
    icon: 'pi pi-file',
    route: Router.ADMIN + Router.SEARCH_DECLARATIONS,
  },
  {
    label: 'Utilisateurs',
    icon: 'pi pi-user',
    route: Router.ADMIN + Router.SEARCH_USER,
  },
  {
    label: 'Entreprises',
    icon: 'pi pi-building',
    route: Router.ADMIN + Router.SEARCH_COMPANY,
  },
  {
    label: 'Factures',
    icon: 'pi pi-euro',
    route: Router.ADMIN + Router.SEARCH_INVOICE,
  },
  {
    label: 'Paiements en attente',
    icon: 'pi pi-pause',
    route: Router.ADMIN + Router.SEARCH_PAYMENT,
  },
])

const switchOnOldInterface = () => {
  window.location.href = `${import.meta.env.VITE_TELEDSN_ADMIN_URL}/changement-interface?nouvelleInterface=false`
}
</script>

<template>
  <Menubar
    class="menu-admin shadow-5 p-2 px-3 border-noround border-none fixed w-full z-5 top-0 padding-xxl"
    :model="items"
  >
    <template #start>
      <router-link to="/admin">
        <img src="@/assets/img/logo.png" class="header-logo hidden xl:flex" alt="Teledsn logo" />
      </router-link>
    </template>
    <template #item="{ item, props, hasSubmenu }">
      <div class="flex justify-center">
        <router-link
          v-if="item.route"
          v-slot="{ href, navigate }"
          :to="item.route"
          custom
          :style="item.route === route.path ? 'color: #55b7ee' : ''"
          :class="item.route === route.path ? 'font-bold' : ''"
        >
          <a v-ripple :href="href" v-bind="props.action" @click="navigate">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
      </div>
    </template>
    <template #end>
      <div class="flex align-items-center gap-2">
        <Button
          @click="switchOnOldInterface"
          label="Basculer sur AI"
          icon="pi pi-arrow-right-arrow-left"
          severity="secondary"
          rounded
          size="small"
        >
          <span class="p-button-icon p-button-icon-left pi pi-arrow-right-arrow-left m-0" />
          <span class="hidden xl:flex p-button-label ml-2">Basculer sur AI</span>
        </Button>
        <Button
          @click="showSettingDialog = true"
          severity="secondary"
          icon="pi pi-sliders-v"
          rounded
          text
          class="hidden xl:flex"
          v-tippy="`Accéder aux paramètres de l'interface`"
        >
          <template #icon>
            <i class="pi pi-sliders-v text-xl" />
          </template>
        </Button>
      </div>
    </template>
  </Menubar>
  <Dialog v-model:visible="showSettingDialog" header="Mes paramètres" :style="{ width: '50rem' }">
    <InterfaceSettings />
  </Dialog>
</template>

<style scoped>
.header-logo {
  height: 50px;
}
</style>
