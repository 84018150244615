import { EditDatableValue } from './declaration.model'

export enum PAYSLIP_STATUS {
  TO_CREATE = 'aCreer',
  TO_COMPLETE = 'aCompleter',
  ON_GOING = 'enCours',
  VALID = 'validé',
}

export enum PAYSLIP_STATUS_LABEL {
  TO_CREATE = 'À créer',
  TO_COMPLETE = 'À compléter',
  ON_GOING = 'En cours',
  VALID = 'Validé',
}

export type PaySlipDashboardResponse = {
  id: number
  periode: string
  salarie: PaySlipDashboardResponseEmployee
  contrat: PaySlipDashboardResponseContract
  status: PAYSLIP_STATUS
}

export type PaySlipDashboardResponseEmployee = {
  id: number
  nom: string
  prenoms: string
  numeroSecu: string
  numeroNtt: string | null
}

export type PaySlipDashboardResponseContract = {
  id: number
  numero: string
  type: string
  libelleEmploi: string
  debut: string
  fin: string | null
}

export type PaySlipDashboard = {
  id: number
  period: string
  rawPeriod: string
  employee: PaySlipDashboardEmployee
  contract: PaySlipDashboardContract
  status: PAYSLIP_STATUS
}

export type PaySlipDashboardEmployee = {
  id: number
  name: string
  firstNames: string
  socialSecurityNumber: string
  temporaryNumber: string | null
}

export type PaySlipDashboardContract = {
  id: number
  number: string
  type: string
  label: string
  start: string
  end: string | null
}

export enum PAY_SLIP_PANEL {
  SALARY = 'salaire',
  EXTRA_HOURS = 'heures-supplementaires',
  EXCEPTIONAL_HOURS = 'heures-exceptionnelles',
  BONUS = 'primes',
  BENEFITS_IN_KIND = 'avantages-en-nature',
  NOT_SUBJECT_TO_CONTRIBUTIONS = 'non-soumis-cotisations',
  ABSENCES = 'absences',
  BASES = 'bases',
  CONTRIBUTIONS = 'cotisations',
  EXEMPTIONS = 'exonerations',
  SALARY_AND_TAX = 'salaire-impot',
  ANNUAL_TOTALS = 'totaux-annuels',
}

export enum PAY_SLIP_PANEL_LABEL {
  SALARY = 'Salaire de base',
  EXTRA_HOURS = 'Heures supplémentaires',
  EXCEPTIONAL_HOURS = 'Heures exceptionnelles',
  BONUS = 'Primes',
  BENEFITS_IN_KIND = 'Avantages en nature',
  NOT_SUBJECT_TO_CONTRIBUTIONS = 'Non soumis à cotisations',
  ABSENCES = 'Absences',
  BASES = 'Bases et assiette',
  CONTRIBUTIONS = 'Cotisations',
  EXEMPTIONS = 'Exonérations',
  SALARY_AND_TAX = 'Salaire et impôt',
  ANNUAL_TOTALS = 'Totaux annuels',
}

export enum PAY_SLIP_ELEMENT_TYPE {
  REMUNERATION = 'Rémunération',
  ABSENCES = 'Absences et indemnités liées',
  CONTRIBUTIONS_EXEMPTIONS = 'Cotisations et exonérations',
  OTHER = 'Autres',
}

export type PaySlipPanel = {
  id: PAY_SLIP_PANEL
  baseSalary?: PaySlipBaseSalary
  elements: EditDatableValue[]
}

export type PaySlip = {
  id: number
  period: { monthYear: string; startDate: string; endDate: string }
  employer: PaySlipEmployer
  employee: PaySlipEmployee
  contract: PaySlipContractInformation
  items: PaySlipPanel[]
  status: PAYSLIP_STATUS
  paymentDate: string
  readonly: boolean
}

export type PaySlipEmployer = {
  address: string
  apen: string
  collectiveConvention: string
  companyName: string
  siret: string
}

export type PaySlipEmployee = {
  address: {
    street: string
    zipCode: string
    city: string
  }
  classification: string
  jobDescription: string
  name: string
  firstName: string
}

export type PaySlipContractInformation = {
  id: number
  canAccessComplementaryHours: boolean
  canAccessExtraHours: boolean
  isPartTime: boolean
}

export type PaySlipBaseSalary = {
  type: string
  quantity: number
  rate: number | null
  calculatedRate: number | null
  amount: number
}

export type PaySlipPayload = {
  debutPeriode: string
  finPeriode: string
  identiteEmployeur: PaySlipEmployerPayload
  identiteSalarie: PaySlipEmployeePayload
  informationContrat?: PaySlipContractInformationPayload
  nonSoumisACotisations: PaySlipNotSubjectToContributionPaylaod[]
  remuneration: PaySlipRemunerationPayload
  absences: PaySlipAbsencesPayload[]
  cotisations: PaySlipContributionPayload[]
}

export type PaySlipEmployerPayload = {
  adresse: string
  apen: string
  conventionCollective: string
  raisonSociale: string
  siret: string
}

export type PaySlipEmployeePayload = {
  adresse: { codePostal: string; localite: string; numeroVoie: string }
  classification: string
  emploi: string
  nom: string
  prenoms: string
}

export type PaySlipContractInformationPayload = {
  aDroitHeuresComplementaires: boolean
  aDroitHeuresSuppplementaires: boolean
  auTempsPartiel: boolean
  id: number
}

export type PaySlipRemunerationPayload = {
  dateVersement: string
  heuresComplementaires: PaySlipHoursPayload[]
  heuresSupplementaires: PaySlipHoursPayload[]
  salaireBase: PaySlipBaseSalaryPayload
  primes: PaySlipBonusAndBenefitsInKindPayload[]
  avantagesEnNature: PaySlipBonusAndBenefitsInKindPayload[]
}

export type PaySlipAbsencesPayload = {
  code: string
  debut: {
    date: string | null
    detail: 'all_day' | 'morning' | 'afternoon' | 'specific_time'
    nombreHeures: number | null
  }
  fin: {
    date: string | null
    detail: 'all_day' | 'morning' | 'afternoon' | 'specific_time'
    nombreHeures: number | null
  }
  retenue: number
  indemnite: number
}

export type PaySlipNotSubjectToContributionPaylaod = {
  code: string
  montant: number
  base: number | null
  quantite: number | null
  valeurUnitaireOuTaux: number | null
}

export type PaySlipBonusAndBenefitsInKindPayload = {
  code: string
  montant: number
  codeDsn: string
  libelle?: string | null
}

export type PaySlipBaseSalaryPayload = {
  montant: number
  quantite: number
  taux: number | null
  type: string
}

export type PaySlipHoursPayload = {
  majoration: number
  nombreHeures: number
  tauxHoraire: number
  montant: number
}

export type PaySlipContributionPayload = {
  code: string
  libelle: string
  famille: string
  base: number
  montantEmployeur: number
  tauxEmployeur: number | null
  montantEmploye: number
  tauxEmploye: number | null
}

export type ExtraHoursOption = {
  code: string
  libelle: string
  majoration: number
}

export type BonusAndBenefitsInKindOption = {
  code: string
  libelle: string
  codeDsn: string | null
}

export type NotSubjectToContributionOption = {
  code: string
  libelle: string
}

export enum YES_OR_NO {
  YES = 'O',
  NO = 'N',
  MAYBE = 'C',
}

export type AbsenceTypeOption = {
  code: string
  libelle: string
  retenue: YES_OR_NO
  indemnite: YES_OR_NO
  tresUtilise: boolean
}

export enum ABSENCE_DETAIL {
  ALL_DAY = 'Toute la journée',
  MORNING = 'Matin',
  AFTERNOON = 'Après-midi',
  SPECIFIC_TIME = "À l'heure",
}

export type Absence = EditDatableValue & {
  isTemporary?: boolean
  count?: string
}

export type AmountsRelatedToAbsenceParams = {
  code: string
  days: number
  hours: number
}

export type ContributionGroup = {
  label: string
  cotisations: ContributionOption[]
}

export type ContributionOption = {
  label: string
  code: string
  group?: string
}

export type ContributionDefaultValue = {
  base: number
  employerRate: number
  employeeRate: number
  employerAmount: number
  employeeAmount: number
}
