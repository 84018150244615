import { defineStore } from 'pinia'
import { teledsnAPI } from '@/services/teledsn-api'
import { User, ChangeEmailPayload, ChangePasswordPayload } from '@/models/user.model'
import { useMainStore } from './main'
import { useCompanyStore } from './company'
import { useEmployeeStore } from './employee'
import { useContractStore } from './contract'
import { useDeclarationStore } from './declaration'
import { useReportingStore } from './reporting'

type State = {
  userInfos: User
  showWarningSessionExpired: boolean
  loading: boolean
}

export const useAuthStore = defineStore('authStore', {
  state: (): State => ({
    userInfos: {} as User,
    showWarningSessionExpired: false,
    loading: false,
  }),
  getters: {},
  actions: {
    async getCookieInfos() {
      const mainStore = useMainStore()
      const companyStore = useCompanyStore()
      companyStore.$reset()
      try {
        const response = await teledsnAPI.getUserCookieInfos()
        this.userInfos = response
        if (this.userInfos.userId && !mainStore.isAdminPage) await mainStore.getCompanies(false)
        if (!mainStore.selectedCompanyIsPresentInCompanies) mainStore.selectedCompany = null
        /**
         * Utilisation de l'id entreprise du cookie
         * pour préselectionner l'entreprise seulement
         * elle est présente dans la liste
         */
        if (this.userInfos.entrepriseId && mainStore.getCompanyById(this.userInfos.entrepriseId)) {
          companyStore.company.id = this.userInfos.entrepriseId
        }
      } catch (error) {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Une erreur est survenue lors de la récupération de l'utilisateur. Veuillez vous reconnecter.",
        })
        this.logout()
      }
    },
    async changeUserEmail(userId: number, payload: ChangeEmailPayload) {
      const mainStore = useMainStore()
      try {
        await teledsnAPI.changeUserEmail(userId, payload)
        this.userInfos.username = payload.new_email
        mainStore.serverResponseStatut.successMessage = `Votre email a bien été changé. Votre nouvel adresse email : ${payload.new_email}`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      }
    },
    async changeUserPassword(userId: number, payload: ChangePasswordPayload) {
      const mainStore = useMainStore()
      try {
        this.loading = true
        await teledsnAPI.changeUserPassword(userId, payload)
        mainStore.serverResponseStatut.successMessage = `Votre mot de passe a bien été modifié.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.loading = false
      }
    },
    async logout() {
      const mainStore = useMainStore()
      try {
        await teledsnAPI.logout(this.userInfos.userId)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'La déconnexion se poursuit.',
        })
      } finally {
        this.$reset()
        useMainStore().$reset()
        useCompanyStore().$reset()
        useEmployeeStore().$reset()
        useContractStore().$reset()
        useDeclarationStore().$reset()
        useReportingStore().$reset()
        window.location.href = `${import.meta.env.VITE_TELEDSN_BACKEND_URL}/se-deconnecter`
      }
    },
  },
  persist: false,
})
