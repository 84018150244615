<script setup>
import { useUtilsStore } from '@/stores/utils'
import { ref, computed } from 'vue'
import TooltipIcon from '@/components/commons/TooltipIcon.vue'
import SelectButton from 'primevue/selectbutton'

const utilsStore = useUtilsStore()

const fontSizeOptions = ref([
  { label: 'Minime', value: 'MINIMAL' },
  { label: 'Compacte', value: 'COMPACT' },
  { label: 'Normale', value: 'NORMAL' },
  { label: 'Grand', value: 'LARGE' },
  { label: 'Extra', value: 'EXTRA' },
])
const displayModeOptions = ref([
  { label: 'Réduite', value: 'COMPACT' },
  { label: 'Normale', value: 'NORMAL' },
  { label: 'Aérée', value: 'LARGE' },
])
const rowsPerPageOptions = computed(() => utilsStore.rowsPerPageOptions)
const fontSizeSelected = computed(() => utilsStore.fontSize)

const setSmallerFont = () => {
  if (fontSizeSelected.value !== 'MINIMAL') {
    const displayModeIndex = fontSizeOptions.value.findIndex((display) => display.value === fontSizeSelected.value)
    utilsStore.setFontSize(fontSizeOptions.value[displayModeIndex - 1].value)
  }
}
const setLargerFont = () => {
  if (fontSizeSelected.value !== 'EXTRA') {
    const displayModeIndex = fontSizeOptions.value.findIndex((display) => display.value === fontSizeSelected.value)
    utilsStore.setFontSize(fontSizeOptions.value[displayModeIndex + 1].value)
  }
}
</script>
<template>
  <div class="grid row-gap-3 justify-content-center">
    <div class="col-12 flex align-items-center gap-3 p-component">
      <label for="applicationTheme" class="col-4 md:col-6 text-right">Thème de l'application :</label>
      <ToggleButton
        v-model="utilsStore.isDarkMode"
        id="applicationTheme"
        onLabel=""
        offLabel=""
        class="p-tooglebutton-rounded"
        v-tippy="`Changer le thème (clair ou sombre) de l'application`"
      >
        <template #icon>
          <i v-if="utilsStore.isDarkMode" class="pi pi-moon text-sm" />
          <i v-else class="pi pi-sun text-sm" />
        </template>
      </ToggleButton>
    </div>
    <div class="col-12 flex align-items-center gap-3 p-component">
      <label for="rowsPerPage" class="col-4 md:col-6 text-right">Nombre d'éléments par page :</label>
      <Dropdown v-model="utilsStore.rowsPerPage" id="rowsPerPage" :options="rowsPerPageOptions" required />
    </div>
    <div class="col-12 flex align-items-center gap-3 p-component">
      <label for="elementSize" class="col-4 md:col-6 text-right">Taille de la police :</label>
      <div
        class="flex flex-column sm:flex-row align-items-center gap-2 border-1 surface-border border-round-3xl py-1 px-2"
      >
        <Button @click="setSmallerFont()" icon="pi pi-minus" rounded outlined class="p-button-sm-mobile" />
        <i
          class="pi pi-circle-fill text-sm text-200"
          :class="{ 'text-lg text-primary': fontSizeSelected === 'MINIMAL' }"
        />
        <i
          class="pi pi-circle-fill text-sm text-200"
          :class="{ 'text-lg text-primary': fontSizeSelected === 'COMPACT' }"
        />
        <i
          class="pi pi-circle-fill text-sm text-200"
          :class="{ 'text-lg text-primary': fontSizeSelected === 'NORMAL' }"
        />
        <i
          class="pi pi-circle-fill text-sm text-200"
          :class="{ 'text-lg text-primary': fontSizeSelected === 'LARGE' }"
        />
        <i
          class="pi pi-circle-fill text-sm text-200"
          :class="{ 'text-lg text-primary': fontSizeSelected === 'EXTRA' }"
        />
        <Button @click="setLargerFont()" icon="pi pi-plus" rounded outlined class="p-button-sm-mobile" />
      </div>
    </div>
    <div class="col-12 flex align-items-center gap-3 p-component">
      <label for="displayMode" class="col-4 md:col-6 text-right">
        Taille des marges intérieures des élements de l'interface :
      </label>
      <SelectButton
        v-model="utilsStore.displayMode"
        id="displayMode"
        :options="displayModeOptions"
        :allowEmpty="false"
        optionLabel="label"
        optionValue="value"
      />
    </div>
    <div class="col-12 flex align-items-center gap-3 p-component">
      <label for="displayMenuInDeclaration" class="col-4 md:col-6 text-right">
        Affichage <b>par défaut</b> du menu de gauche dans vos déclarations :
      </label>
      <ToggleButton
        v-model="utilsStore.displayMenuInDeclaration"
        id="displayMenuInDeclaration"
        onLabel="Afficher"
        offLabel="Masquer"
      />
      <TooltipIcon text="Vous aurez toujours la possibilité d'afficher ou masquer le menu pendant vos déclarations." />
    </div>
  </div>
</template>
