import { STATUS } from '@/models/declaration.model'

export type WorkStoppage = {
  startOfWorkStoppage: any
  resumingDate: any
  reasonOfWorkStoppage: string
  resumingReason: string
  accidentDate: any
  subrogation: boolean
  subrogationStartDate: any
  subrogationEndDate: any
  employerIBAN: string
  employerBIC: string
  collectorSiret: string
}

export type Notice = {
  noticeType: string
  noticeStartDate: any
  noticeEndDate: any
}

export type EndingContract = {
  contractId: number | null
  generalInformations: GeneralInformations
  lastMonthRevenue: LastMonthRevenue
  complementaryInfos: ComplementaryInfos
  complementaryInputs?: string[]
}

export type EndingContractReason = {
  code: string
  libelle: string
}

export type LastMonthRevenue = {
  netTax: number | null
  netPayable: number | null
  hoursWorked: number | null
  unemploymentInsuranceGross: number | null
  untaxedGrossIncome: number | null
  unusedHoursDIF: number | null
  noticeDuration: number | null
  compensationAmount: number | null
  netHourlyWages: number | null
  daysWorkedNumberTooltip?: string
}

export type ComplementaryInfos = {
  notice: Notice
  specialStatus: string | null
  transaction: boolean
  pensionPortability: boolean
}

export type WorkStoppageReportPayload = {
  nature: string
  dateDebut: string
  dateReprise: string | null
  entrepriseId?: number
  salarieId?: number
  motifArret: string
  dateAccident: string
  subrogation: boolean
  debutSubrogation: string
  finSubrogation: string
  ibanSubrogation: string
  bicSubrogation: string
  siretCollecteurSubrogation: string
  motifReprise: string | null
}

export type GeneralInformations = {
  isAnticipatedDsnNecessary?: boolean
  mustDoFranceTravailProcess?: boolean
  endingDate: any
  endingReason: string | null
  lastDayWork: any
  firingDate: any
  conventionDate: any
  notificationDate: any
}

export enum REPORT_TYPE {
  DSN_MENSUELLE = '01',
  DSN_FIN_CONTRAT_TRAVAIL = '02',
  DSN_ARRET_TRAVAIL = '04',
  DSN_REPRISE_SUITE_ARRET_TRAVAIL = '05',
  DSN_FIN_CONTRAT_TRAVAIL_UNIQUE = '07',
}

export enum WORK_STOPPAGE_REASONS {
  CONGE_ACCIDENT_TRAJET = '04',
  CONGE_ACCIDENT_TRAVAIL = '06',
  CONGE_MALADIE_PROFESSIONNELLE = '05',
  FEMME_ENCEINTE_DISPENSE = '07',
  MALADIE = '01',
  MATERNITE = '02',
  PARTERNITE = '03',
}

export type EndingContractReportPayload = {
  entrepriseId?: number
  salarieId?: number
  contratId?: number
  dateFin: string | Date | null
  motifFin: string | null
  dateNotification: string | Date | null
  dernierJourTravaille: string | Date | null
  transaction: boolean
  portabilitePrevoyance: boolean
  nbDif: number | null
  dureePreavis: number | null
  montantIndemnite: number | null
  salaireHoraireNet: number | null
  statutParticulier: string | null
  typePreavis: string
  debutPreavis: string | Date | null
  finPreavis: string | Date | null
  brutDeplafonne: number
  brutAC: number
  netFiscal: number
  netAPayer: number
  nbHeuresTravaillees: number
  dateLicenciement: string | Date | null
  dateConvention: string | Date | null
}

export type LastSendingReportDetails = {
  dernierEnvoi?: string | Date | null
  status?: STATUS
  statut?: string
  urlAed?: string | null
  erreurs?: string[]
  messageBloquants?: string[]
  created?: string | Date | null
  ouvrable: boolean
  renvoi: boolean
}

export enum ENDING_CONTRACT_REASON {
  RUPTURE_MOTIF_ECO_CSP = '026',
  FIN_PERIODE_ESSAI_INIT_EMPLOYEUR = '034',
  FIN_PERIODE_ESSAI_INIT_SALARIE = '035',
  RUPTURE_CONVENTIONNELLE = '043',
}

export enum NOTICE_TYPE {
  NO_APPLICABLE_NOTICE = '90',
  NOTICE_NOT_GIVEN_NOT_PAID_CSP = '10',
}
