export const dateToFRFormat = (date: Date): string => {
  let formattedDate = ''

  if (date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime())) {
    const annee = date.getFullYear().toString()
    const mois = date.getMonth() + 1 >= 10 ? (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1).toString()
    const jour = date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString()

    formattedDate = jour + '/' + mois + '/' + annee
  }

  return formattedDate
}

export const dateFRtoBasic = (dateFR: string): string => {
  if (!dateFR) return ''

  const day = dateFR.substr(0, 2)
  const month = dateFR.substr(3, 2)
  const year = dateFR.substr(6, 4)

  return year + '-' + month + '-' + day
}

export const capitalize = (str: string): string => {
  if (!str || str === null) return str

  const words = str.toLowerCase().split(/\b/)

  for (let i = 0; i < words.length; i++) {
    if (/^[a-zA-Z']+$/.test(words[i])) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
    }
  }

  return words.join('')
}

const userAgent: string = navigator.userAgent || navigator.vendor

export const isMobileDevice = (): boolean => {
  const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
  return regexs.some((b) => userAgent.match(b))
}

const isTabletDevice = (): boolean => {
  const regex =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
  return regex.test(userAgent.toLowerCase())
}

export const isDesktopDevice = (): boolean => !isMobileDevice() && !isTabletDevice()

export const validateEmailFormat = (email: string) => {
  if (!email) return false
  const regex = new RegExp(
    `([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])`,
  )
  return regex.test(email)
}

export const determineMonthLabelPeriod = (period: string) => {
  if (!period || period === '' || !period.includes('/')) return period
  const month = period.substring(0, 2)
  const year = period.substring(3, 7)
  let monthLabel = ''

  switch (month) {
    case '01':
      monthLabel = 'Janvier'
      break
    case '02':
      monthLabel = 'Février'
      break
    case '03':
      monthLabel = 'Mars'
      break
    case '04':
      monthLabel = 'Avril'
      break
    case '05':
      monthLabel = 'Mai'
      break
    case '06':
      monthLabel = 'Juin'
      break
    case '07':
      monthLabel = 'Juillet'
      break
    case '08':
      monthLabel = 'Août'
      break
    case '09':
      monthLabel = 'Septembre'
      break
    case '10':
      monthLabel = 'Octobre'
      break
    case '11':
      monthLabel = 'Novembre'
      break
    case '12':
      monthLabel = 'Décembre'
      break
  }

  return monthLabel + ' ' + year
}

export const beautifySocialNumber = (socialNumber: string): string => {
  return (
    socialNumber.substring(0, 1) +
    ' ' +
    socialNumber.substring(1, 3) +
    ' ' +
    socialNumber.substring(3, 5) +
    ' ' +
    socialNumber.substring(5, 7) +
    ' ' +
    socialNumber.substring(7, 10) +
    ' ' +
    socialNumber.substring(10, 13) +
    ' ' +
    socialNumber.substring(13, socialNumber.length)
  )
}

export const getElementPosition = (el: Element): { top: number; left: number } => {
  if (!el) {
    return { top: 0, left: 0 }
  }
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.scrollX || document.documentElement.scrollLeft,
    scrollTop = window.scrollY || document.documentElement.scrollTop
  return { top: rect.top + scrollTop + 45, left: rect.left + scrollLeft }
}

export const generateYearsBetween = (startYear?: number, endYear?: number): number[] => {
  const endDate = endYear || new Date().getFullYear()
  let startDate = startYear || new Date().getFullYear() - 6

  const years = []

  for (let i = startDate; i <= endDate; i++) {
    years.push(startDate)
    startDate++
  }
  return years.reverse()
}

export const getStringMapContent = (key?: string, map?: Map<string, string[]>) => {
  if (key && map && map instanceof Map) {
    return map.get(key) ?? []
  }

  return []
}

export const convertRecordToMap = (data?: Record<string, string[]>): Map<string, string[]> => {
  const map = new Map<string, string[]>()
  if (data) {
    Object.entries<string[]>(data).forEach(([key, value]) => {
      map.set(key, value)
    })
  }
  return map
}

export const parseDate = (value: string) => {
  if (value.length === 10) {
    const [day, month, year] = value.split('/').map(Number)
    const date = new Date(year, month - 1, day)

    if (date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year) {
      return date
    }
  }

  return value
}

export const addSlashInDateIfNecessary = (value: string, cursorPos: number) => {
  const cleaned = value.replace(/\D/g, '') // Supprime tout sauf les chiffres
  let valueAfterProcessing = ''
  let newCursorPos = cursorPos

  for (let i = 0; i < cleaned.length; i++) {
    valueAfterProcessing += cleaned[i]
    if (i === 1 || i === 3) {
      valueAfterProcessing += '/'
      if (cursorPos > i) newCursorPos++ // Décale le curseur si on est après un `/`
    }
  }

  return { valueAfterProcessing, newCursorPos }
}

export const isValidDate = (value: unknown): value is Date => {
  return value instanceof Date && !isNaN(value.getTime())
}

export const isPasswordValid = (password: string) => {
  if (!password) return false
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  return regex.test(password)
}
