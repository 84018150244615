import { determineMonthLabelPeriod } from '@/utils'
import {
  PAY_SLIP_PANEL,
  PAY_SLIP_PANEL_LABEL,
  PaySlipDashboardResponse,
  PaySlipDashboard,
  PAYSLIP_STATUS,
  PAYSLIP_STATUS_LABEL,
  PAY_SLIP_ELEMENT_TYPE,
  PaySlipPayload,
  PaySlip,
  PaySlipHoursPayload,
  PaySlipBaseSalary,
  PaySlipBaseSalaryPayload,
  PaySlipBonusAndBenefitsInKindPayload,
  PaySlipNotSubjectToContributionPaylaod,
  Absence,
  ABSENCE_DETAIL,
  PaySlipAbsencesPayload,
  PaySlipPanel,
  PaySlipContributionPayload,
} from './payslip.model'
import { EditDatableValue } from './declaration.model'
import { ObjKeyValueString } from './main.model'
import { dateFRtoBasic, dateToFRFormat } from '@/utils/index'

export class Helper {
  static unreferencedBonusOptions: ObjKeyValueString[] = [
    { key: '026', value: `Prime exceptionnelle liée à l'activité` },
    { key: '027', value: `Prime liée à l'activité` },
    { key: '028', value: `Prime non liée à l'activité` },
  ]

  static unreferencedElementsPrefix = 'SANS_CODE_'

  static MEAL_TICKET_CODE = 'SANS_COTIZ_03'

  static determineStatusLabel(status: PAYSLIP_STATUS): PAYSLIP_STATUS_LABEL {
    switch (status) {
      case PAYSLIP_STATUS.VALID:
        return PAYSLIP_STATUS_LABEL.VALID
      case PAYSLIP_STATUS.TO_COMPLETE:
        return PAYSLIP_STATUS_LABEL.TO_COMPLETE
      case PAYSLIP_STATUS.TO_CREATE:
        return PAYSLIP_STATUS_LABEL.TO_CREATE
      case PAYSLIP_STATUS.ON_GOING:
        return PAYSLIP_STATUS_LABEL.ON_GOING
    }
  }

  static determineStatusSeverity(status: PAYSLIP_STATUS): string {
    switch (status) {
      case PAYSLIP_STATUS.VALID:
        return 'success'
      case PAYSLIP_STATUS.TO_COMPLETE:
      case PAYSLIP_STATUS.TO_CREATE:
        return 'warning'
      case PAYSLIP_STATUS.ON_GOING:
        return 'info'
      default:
        return 'info'
    }
  }

  static determinePanelOrder(panel: PAY_SLIP_PANEL): number {
    switch (panel) {
      case PAY_SLIP_PANEL.SALARY:
        return 1
      case PAY_SLIP_PANEL.EXTRA_HOURS:
        return 2
      case PAY_SLIP_PANEL.EXCEPTIONAL_HOURS:
        return 2
      case PAY_SLIP_PANEL.BONUS:
        return 3
      case PAY_SLIP_PANEL.BENEFITS_IN_KIND:
        return 4
      case PAY_SLIP_PANEL.NOT_SUBJECT_TO_CONTRIBUTIONS:
        return 5
      case PAY_SLIP_PANEL.ABSENCES:
        return 6
      case PAY_SLIP_PANEL.BASES:
        return 7
      case PAY_SLIP_PANEL.CONTRIBUTIONS:
        return 8
      case PAY_SLIP_PANEL.EXEMPTIONS:
        return 9
      case PAY_SLIP_PANEL.SALARY_AND_TAX:
        return 10
      case PAY_SLIP_PANEL.ANNUAL_TOTALS:
        return 11
    }
  }

  static orderPanels(panels: PaySlipPanel[]): PaySlipPanel[] {
    return panels.sort((a, b) => {
      if (this.determinePanelOrder(a.id) > this.determinePanelOrder(b.id)) return 1
      if (this.determinePanelOrder(a.id) < this.determinePanelOrder(b.id)) return -1
      return 0
    })
  }

  static determineTimeOfAbsence(absence: Absence): { days: number; hours: number } {
    let days = 0
    let hours = 0

    if (!absence.startDate?.value || !absence.endDate?.value) return { days: 0, hours: 0 }

    if (
      absence.startDate?.value &&
      absence.startDate?.value instanceof Date &&
      absence.endDate?.value &&
      absence.endDate?.value instanceof Date
    ) {
      const startTime = absence.startDate.value.getTime()
      const endTime = absence.endDate.value.getTime()
      const timeDiff = endTime - startTime
      const daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
      days = daysDiff + 1
    }

    if (
      absence.startDate?.dateDetails === ABSENCE_DETAIL.MORNING ||
      absence.startDate?.dateDetails === ABSENCE_DETAIL.AFTERNOON
    ) {
      days -= 0.5
    }
    if (
      absence.endDate?.dateDetails === ABSENCE_DETAIL.MORNING ||
      absence.endDate?.dateDetails === ABSENCE_DETAIL.AFTERNOON
    ) {
      days -= 0.5
    }

    if (absence.startDate?.dateDetails === ABSENCE_DETAIL.SPECIFIC_TIME && absence.startDate?.hours) {
      hours += absence.startDate?.hours
      days -= 1
    }
    if (absence.endDate?.dateDetails === ABSENCE_DETAIL.SPECIFIC_TIME && absence.endDate?.hours) {
      hours += absence.endDate?.hours
      days -= 1
    }

    return { days, hours }
  }

  static addElementOptions = {
    Rémunération: [
      { key: PAY_SLIP_PANEL.SALARY, value: PAY_SLIP_PANEL_LABEL.SALARY },
      { key: PAY_SLIP_PANEL.EXTRA_HOURS, value: PAY_SLIP_PANEL_LABEL.EXTRA_HOURS },
      { key: PAY_SLIP_PANEL.EXCEPTIONAL_HOURS, value: PAY_SLIP_PANEL_LABEL.EXCEPTIONAL_HOURS },
      { key: PAY_SLIP_PANEL.BONUS, value: PAY_SLIP_PANEL_LABEL.BONUS },
      { key: PAY_SLIP_PANEL.BENEFITS_IN_KIND, value: PAY_SLIP_PANEL_LABEL.BENEFITS_IN_KIND },
      { key: PAY_SLIP_PANEL.NOT_SUBJECT_TO_CONTRIBUTIONS, value: PAY_SLIP_PANEL_LABEL.NOT_SUBJECT_TO_CONTRIBUTIONS },
    ],
    'Absences et indemnités liées': [{ key: PAY_SLIP_PANEL.ABSENCES, value: PAY_SLIP_PANEL_LABEL.ABSENCES }],
    'Cotisations et exonérations': [
      { key: PAY_SLIP_PANEL.BASES, value: PAY_SLIP_PANEL_LABEL.BASES },
      { key: PAY_SLIP_PANEL.CONTRIBUTIONS, value: PAY_SLIP_PANEL_LABEL.CONTRIBUTIONS },
      { key: PAY_SLIP_PANEL.EXEMPTIONS, value: PAY_SLIP_PANEL_LABEL.EXEMPTIONS },
    ],
    Autres: [],
  }

  static transformPayslipsToPayslipsDashboard(payload: PaySlipDashboardResponse[]): PaySlipDashboard[] {
    const payslips: PaySlipDashboard[] = []
    payload.forEach((payslip) => {
      payslips.push({
        id: payslip.id,
        period: determineMonthLabelPeriod(payslip.periode),
        rawPeriod: payslip.periode,
        employee: {
          id: payslip.salarie.id,
          name: payslip.salarie.nom,
          firstNames: payslip.salarie.prenoms,
          socialSecurityNumber: payslip.salarie.numeroSecu,
          temporaryNumber: payslip.salarie.numeroNtt,
        },
        contract: {
          id: payslip.contrat.id,
          number: payslip.contrat.numero,
          type: payslip.contrat.type,
          label: payslip.contrat.libelleEmploi,
          start: payslip.contrat.debut,
          end: payslip.contrat.fin,
        },
        status: payslip.status,
      })
    })

    return payslips
  }

  static determinePaySlipPanelLabelByEnum(panel: PAY_SLIP_PANEL) {
    switch (panel) {
      case PAY_SLIP_PANEL.SALARY:
        return PAY_SLIP_PANEL_LABEL.SALARY
      case PAY_SLIP_PANEL.EXTRA_HOURS:
        return PAY_SLIP_PANEL_LABEL.EXTRA_HOURS
      case PAY_SLIP_PANEL.EXCEPTIONAL_HOURS:
        return PAY_SLIP_PANEL_LABEL.EXCEPTIONAL_HOURS
      case PAY_SLIP_PANEL.BONUS:
        return PAY_SLIP_PANEL_LABEL.BONUS
      case PAY_SLIP_PANEL.BENEFITS_IN_KIND:
        return PAY_SLIP_PANEL_LABEL.BENEFITS_IN_KIND
      case PAY_SLIP_PANEL.NOT_SUBJECT_TO_CONTRIBUTIONS:
        return PAY_SLIP_PANEL_LABEL.NOT_SUBJECT_TO_CONTRIBUTIONS
      case PAY_SLIP_PANEL.ABSENCES:
        return PAY_SLIP_PANEL_LABEL.ABSENCES
      case PAY_SLIP_PANEL.BASES:
        return PAY_SLIP_PANEL_LABEL.BASES
      case PAY_SLIP_PANEL.CONTRIBUTIONS:
        return PAY_SLIP_PANEL_LABEL.CONTRIBUTIONS
      case PAY_SLIP_PANEL.EXEMPTIONS:
        return PAY_SLIP_PANEL_LABEL.EXEMPTIONS
      case PAY_SLIP_PANEL.SALARY_AND_TAX:
        return PAY_SLIP_PANEL_LABEL.SALARY_AND_TAX
      case PAY_SLIP_PANEL.ANNUAL_TOTALS:
        return PAY_SLIP_PANEL_LABEL.ANNUAL_TOTALS
      default:
        return ''
    }
  }

  static determineTypePanel(panel: PAY_SLIP_PANEL) {
    switch (panel) {
      case PAY_SLIP_PANEL.SALARY:
      case PAY_SLIP_PANEL.EXTRA_HOURS:
      case PAY_SLIP_PANEL.EXCEPTIONAL_HOURS:
      case PAY_SLIP_PANEL.BONUS:
      case PAY_SLIP_PANEL.BENEFITS_IN_KIND:
      case PAY_SLIP_PANEL.NOT_SUBJECT_TO_CONTRIBUTIONS:
        return PAY_SLIP_ELEMENT_TYPE.REMUNERATION
      case PAY_SLIP_PANEL.ABSENCES:
        return PAY_SLIP_ELEMENT_TYPE.ABSENCES
      case PAY_SLIP_PANEL.BASES:
      case PAY_SLIP_PANEL.CONTRIBUTIONS:
      case PAY_SLIP_PANEL.EXEMPTIONS:
        return PAY_SLIP_ELEMENT_TYPE.CONTRIBUTIONS_EXEMPTIONS
      default:
        return PAY_SLIP_ELEMENT_TYPE.OTHER
    }
  }

  static transformPaySlipPayloadToPaySlip(payload: PaySlipPayload, id: number): PaySlip {
    const paySlip: PaySlip = {
      id,
      period: {
        monthYear: payload.debutPeriode.slice(3),
        startDate: payload.debutPeriode,
        endDate: payload.finPeriode,
      },
      employee: {
        address: {
          street: payload.identiteSalarie.adresse.numeroVoie,
          zipCode: payload.identiteSalarie.adresse.codePostal,
          city: payload.identiteSalarie.adresse.localite,
        },
        classification: payload.identiteSalarie.classification,
        jobDescription: payload.identiteSalarie.emploi,
        name: payload.identiteSalarie.nom,
        firstName: payload.identiteSalarie.prenoms,
      },
      employer: {
        address: payload.identiteEmployeur.adresse,
        apen: payload.identiteEmployeur.apen,
        collectiveConvention: payload.identiteEmployeur.conventionCollective,
        companyName: payload.identiteEmployeur.raisonSociale,
        siret: payload.identiteEmployeur.siret,
      },
      contract: {
        id: payload.informationContrat?.id ?? 0,
        canAccessComplementaryHours: Boolean(payload.informationContrat?.aDroitHeuresComplementaires),
        canAccessExtraHours: Boolean(payload.informationContrat?.aDroitHeuresSuppplementaires),
        isPartTime: Boolean(payload.informationContrat?.auTempsPartiel),
      },
      items: [],
      paymentDate: payload.remuneration.dateVersement,
      status: PAYSLIP_STATUS.TO_COMPLETE,
      readonly: false,
    }

    if (payload.remuneration.salaireBase.montant) {
      paySlip.items.push({
        id: PAY_SLIP_PANEL.SALARY,
        elements: [],
        baseSalary: {
          type: payload.remuneration.salaireBase.type,
          quantity: payload.remuneration.salaireBase.quantite,
          rate: payload.remuneration.salaireBase.taux,
          calculatedRate: payload.remuneration.salaireBase.taux,
          amount: payload.remuneration.salaireBase.montant,
        },
      })
    }

    if (payload.remuneration.heuresSupplementaires && payload.remuneration.heuresSupplementaires.length > 0) {
      paySlip.items.push({
        id: PAY_SLIP_PANEL.EXTRA_HOURS,
        elements: Helper.transformPaySlipHoursPayloadToElements(payload.remuneration.heuresSupplementaires),
      })
    }

    if (payload.remuneration.heuresComplementaires && payload.remuneration.heuresComplementaires.length > 0) {
      paySlip.items.push({
        id: PAY_SLIP_PANEL.EXTRA_HOURS,
        elements: Helper.transformPaySlipHoursPayloadToElements(payload.remuneration.heuresComplementaires),
      })
    }

    if (payload.remuneration.primes && payload.remuneration.primes.length > 0) {
      paySlip.items.push({
        id: PAY_SLIP_PANEL.BONUS,
        elements: Helper.transformPaySlipBonusAndBenefitsInKindPayloadToElements(payload.remuneration.primes),
      })
    }

    if (payload.remuneration.avantagesEnNature && payload.remuneration.avantagesEnNature.length > 0) {
      paySlip.items.push({
        id: PAY_SLIP_PANEL.BENEFITS_IN_KIND,
        elements: Helper.transformPaySlipBonusAndBenefitsInKindPayloadToElements(
          payload.remuneration.avantagesEnNature,
        ),
      })
    }

    if (payload.nonSoumisACotisations && payload.nonSoumisACotisations.length > 0) {
      paySlip.items.push({
        id: PAY_SLIP_PANEL.NOT_SUBJECT_TO_CONTRIBUTIONS,
        elements: Helper.transformPaySlipNotSubjectToContributionPaylaodToElements(payload.nonSoumisACotisations),
      })
    }

    if (payload.absences && payload.absences.length > 0) {
      paySlip.items.push({
        id: PAY_SLIP_PANEL.ABSENCES,
        elements: Helper.transformPaySlipAbsencesPayloadToPanel(payload.absences),
      })
    }

    if (payload.cotisations && payload.cotisations.length > 0) {
      paySlip.items.push({
        id: PAY_SLIP_PANEL.CONTRIBUTIONS,
        elements: Helper.transformPaySlipContributionsPayloadToPanel(payload.cotisations),
      })
    }

    return paySlip
  }

  static transformPaySlipContributionsPayloadToPanel(payload: PaySlipContributionPayload[]) {
    const elements: EditDatableValue[] = []

    payload.forEach((element) => {
      elements.push({
        code: element.code,
        label: element.libelle,
        group: element.famille,
        base: { value: element.base, canBeZero: true, required: true },
        rate: { value: element.tauxEmployeur, canBeZero: true, required: true },
        amount: { value: element.montantEmployeur, canBeZero: true, required: true },
        otherRate: { value: element.tauxEmploye, canBeZero: true, required: true },
        otherBase: { value: element.montantEmploye, canBeZero: true, required: true },
        key: element.code,
      })
    })

    return elements
  }

  static transformPaySlipAbsencesPayloadToPanel(payload: PaySlipAbsencesPayload[]) {
    const elements: Absence[] = []

    payload.forEach((element, index) => {
      elements.push({
        key: element.code,
        startDate: {
          value: new Date(dateFRtoBasic(element.debut.date ?? '')),
          required: true,
          dateDetails: Helper.transformPayloadToAbsenceDetail(element.debut.detail),
          hours: element.debut.nombreHeures ?? 0,
        },
        endDate: {
          value: new Date(dateFRtoBasic(element.fin.date ?? '')),
          required: true,
          dateDetails: Helper.transformPayloadToAbsenceDetail(element.fin.detail),
          hours: element.fin.nombreHeures ?? 0,
        },
        amount: { value: element.retenue, required: true, canBeZero: true },
        base: { value: element.indemnite, required: true, canBeZero: true },
        isTemporary: false,
        code: `${element.code}_${index}`,
      })
    })

    return elements
  }

  static transformPaySlipNotSubjectToContributionPaylaodToElements(payload: PaySlipNotSubjectToContributionPaylaod[]) {
    const elements: EditDatableValue[] = []

    payload.forEach((element) => {
      elements.push({
        key: element.code,
        amount: { value: element.montant, required: true, canBeZero: false },
        base: { value: element.base, required: false, canBeZero: true },
        otherBase: { value: element.quantite, required: false, canBeZero: true },
        rate: { value: element.valeurUnitaireOuTaux, required: false, canBeZero: true },
        code: element.code,
        haveModal: element.code === this.MEAL_TICKET_CODE,
      })
    })

    return elements
  }

  static transformPaySlipBonusAndBenefitsInKindPayloadToElements(payload: PaySlipBonusAndBenefitsInKindPayload[]) {
    const elements: EditDatableValue[] = []

    payload.forEach((otherIncome) => {
      elements.push({
        key: otherIncome.code,
        amount: { value: otherIncome.montant, required: true, canBeZero: false },
        item: {
          value: otherIncome.codeDsn,
          required: true,
          options: Helper.unreferencedBonusOptions,
          loading: false,
          needCall: false,
        },
        string: { value: otherIncome.libelle ?? null, required: true },
        code: otherIncome.code,
      })
    })

    return elements
  }

  static transformPaySlipHoursPayloadToElements(payload: PaySlipHoursPayload[]) {
    const elements: EditDatableValue[] = []

    payload.forEach((hour, index) => {
      elements.push({
        key: index.toString(),
        otherBase: { value: hour.majoration, required: true, canBeZero: false },
        amount: { value: hour.montant, required: true, canBeZero: false },
        base: { value: hour.nombreHeures, required: true, canBeZero: false },
        rate: { value: hour.tauxHoraire, required: true, canBeZero: false },
        code: index.toString(),
      })
    })

    return elements
  }

  static transformPaySlipToPaySlipPayload(paySlip: PaySlip) {
    const payload: PaySlipPayload = {
      debutPeriode: paySlip.period.startDate,
      finPeriode: paySlip.period.endDate,
      identiteEmployeur: {
        adresse: paySlip.employer.address,
        apen: paySlip.employer.apen,
        conventionCollective: paySlip.employer.collectiveConvention,
        raisonSociale: paySlip.employer.companyName,
        siret: paySlip.employer.siret,
      },
      identiteSalarie: {
        adresse: {
          numeroVoie: paySlip.employee.address.street,
          codePostal: paySlip.employee.address.zipCode,
          localite: paySlip.employee.address.city,
        },
        classification: paySlip.employee.classification,
        emploi: paySlip.employee.jobDescription,
        nom: paySlip.employee.name,
        prenoms: paySlip.employee.firstName,
      },
      nonSoumisACotisations: Helper.transformNotSubjectToContributionsPanelToPayload(
        paySlip.items.find((item) => item.id === PAY_SLIP_PANEL.NOT_SUBJECT_TO_CONTRIBUTIONS)?.elements,
      ),
      remuneration: {
        dateVersement: paySlip.paymentDate,
        salaireBase: Helper.transformPaySlipBaseSalaryToPayload(
          paySlip.items.find((item) => item.id === PAY_SLIP_PANEL.SALARY)?.baseSalary,
        ),
        heuresSupplementaires: Helper.transformHoursPanelToPayload(
          paySlip.items.find((item) => item.id === PAY_SLIP_PANEL.EXTRA_HOURS)?.elements,
        ),
        heuresComplementaires: Helper.transformHoursPanelToPayload(
          paySlip.items.find((item) => item.id === PAY_SLIP_PANEL.EXCEPTIONAL_HOURS)?.elements,
        ),
        primes: Helper.transformBonusAndBenefitsInKindPanelToPayload(
          paySlip.items.find((item) => item.id === PAY_SLIP_PANEL.BONUS)?.elements,
        ),
        avantagesEnNature: Helper.transformBonusAndBenefitsInKindPanelToPayload(
          paySlip.items.find((item) => item.id === PAY_SLIP_PANEL.BENEFITS_IN_KIND)?.elements,
        ),
      },
      absences: Helper.transformAbsencesPanelToPayload(
        paySlip.items.find((item) => item.id === PAY_SLIP_PANEL.ABSENCES)?.elements,
      ),
      cotisations: Helper.transformContributionsPanelToPayload(
        paySlip.items.find((item) => item.id === PAY_SLIP_PANEL.CONTRIBUTIONS)?.elements,
      ),
    }

    return payload
  }

  static transformAbsencesPanelToPayload(elements?: EditDatableValue[]) {
    const payload: PaySlipAbsencesPayload[] = []

    elements?.forEach((element) => {
      payload.push({
        code: element.key,
        debut: {
          date: element.startDate?.value ? dateToFRFormat(new Date(element.startDate?.value)) : null,
          detail: this.determineAbsenceDetailPayload(element.startDate?.dateDetails),
          nombreHeures: element.startDate?.hours ?? null,
        },
        fin: {
          date: element.endDate?.value ? dateToFRFormat(new Date(element.endDate?.value)) : null,
          detail: this.determineAbsenceDetailPayload(element.endDate?.dateDetails),
          nombreHeures: element.endDate?.hours ?? null,
        },
        retenue: element.amount?.value ?? 0,
        indemnite: element.base?.value ?? 0,
      })
    })

    return payload
  }

  static determineAbsenceDetailPayload(
    absenceDetail?: ABSENCE_DETAIL,
  ): 'all_day' | 'morning' | 'afternoon' | 'specific_time' {
    switch (absenceDetail) {
      case ABSENCE_DETAIL.ALL_DAY:
        return 'all_day'
      case ABSENCE_DETAIL.MORNING:
        return 'morning'
      case ABSENCE_DETAIL.AFTERNOON:
        return 'afternoon'
      case ABSENCE_DETAIL.SPECIFIC_TIME:
        return 'specific_time'
      default:
        return 'all_day'
    }
  }

  static transformPayloadToAbsenceDetail(
    detail?: 'all_day' | 'morning' | 'afternoon' | 'specific_time',
  ): ABSENCE_DETAIL {
    switch (detail) {
      case 'all_day':
        return ABSENCE_DETAIL.ALL_DAY
      case 'morning':
        return ABSENCE_DETAIL.MORNING
      case 'afternoon':
        return ABSENCE_DETAIL.AFTERNOON
      case 'specific_time':
        return ABSENCE_DETAIL.SPECIFIC_TIME
      default:
        return ABSENCE_DETAIL.ALL_DAY
    }
  }

  static transformPaySlipBaseSalaryToPayload(baseSalary?: PaySlipBaseSalary) {
    const payload: PaySlipBaseSalaryPayload = {
      montant: baseSalary?.amount ?? 0,
      quantite: baseSalary?.quantity ?? 0,
      taux: baseSalary?.rate ?? null,
      type: baseSalary?.type ?? '',
    }

    return payload
  }

  static transformHoursPanelToPayload(elements?: EditDatableValue[]) {
    const payload: PaySlipHoursPayload[] = []

    elements?.forEach((element) => {
      payload.push({
        majoration: element.otherBase?.value ?? 0,
        nombreHeures: element.base?.value ?? 0,
        tauxHoraire: element.rate?.value ?? 0,
        montant: element.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformBonusAndBenefitsInKindPanelToPayload(elements?: EditDatableValue[]) {
    const payload: PaySlipBonusAndBenefitsInKindPayload[] = []

    elements?.forEach((element) => {
      payload.push({
        code: element.code,
        libelle: element.string?.value,
        codeDsn: element.item?.value ?? '',
        montant: element.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformNotSubjectToContributionsPanelToPayload(elements?: EditDatableValue[]) {
    const payload: PaySlipNotSubjectToContributionPaylaod[] = []

    elements?.forEach((element) => {
      payload.push({
        code: element.code,
        base: element.base?.value ?? null,
        quantite: element.otherBase?.value ?? null,
        valeurUnitaireOuTaux: element.rate?.value ?? null,
        montant: element.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformContributionsPanelToPayload(elements?: EditDatableValue[]) {
    const payload: PaySlipContributionPayload[] = []

    elements?.forEach((element) => {
      payload.push({
        code: element.code,
        libelle: element.label ?? '',
        famille: element.group ?? '',
        base: element.base?.value ?? 0,
        tauxEmployeur: element.rate?.value ?? null,
        montantEmployeur: element.amount?.value ?? 0,
        tauxEmploye: element.otherRate?.value ?? null,
        montantEmploye: element.otherBase?.value ?? 0,
      })
    })

    return payload
  }
}
