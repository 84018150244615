<script setup lang="ts">
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { ref, computed } from 'vue'
import faqContent, { FAQ_TAG } from '@/assets/content/faq/faq'

const emit = defineEmits(['consultGuide', 'contactUs'])

const elements = ref(faqContent)
const tagFilters = ref<FAQ_TAG[]>([FAQ_TAG.COMPANY, FAQ_TAG.EMPLOYEE, FAQ_TAG.CONTRACT, FAQ_TAG.DECLARATION])
const currentTagFilter = ref<'' | FAQ_TAG>('')
const search = ref('')
const filteredElements = computed(() => {
  const filterBySearch = elements.value.filter(
    (element) =>
      element.title.toLocaleLowerCase().includes(search.value) ||
      element.answer.toLocaleLowerCase().includes(search.value),
  )
  const thenFilterByTag = filterBySearch.filter((element) =>
    currentTagFilter.value ? element.tag === currentTagFilter.value : true,
  )

  return thenFilterByTag
})

const setCurrentTagFilter = (filter: FAQ_TAG) => {
  if (currentTagFilter.value === filter) {
    currentTagFilter.value = ''
  } else {
    currentTagFilter.value = filter
  }
}

const determineFAQTagSeverity = (tag: FAQ_TAG) => {
  switch (tag) {
    case FAQ_TAG.COMPANY:
      return 'primary'
    case FAQ_TAG.EMPLOYEE:
      return 'info'
    case FAQ_TAG.CONTRACT:
      return 'secondary'
    case FAQ_TAG.DECLARATION:
      return 'contrast'
    default:
      return 'primary'
  }
}
</script>
<template>
  <div class="grid grid-nogutter row-gap-2 gap-2 mt-2 mb-4">
    <IconField class="col-12">
      <InputIcon class="pi pi-search" />
      <InputText
        v-model="search"
        placeholder="Rechercher par mot clé"
        class="w-full"
        aria-label="Filter la FAQ par mot clé"
        data-sentry-unmask
      />
    </IconField>
    <small class="col-12 pl-1">ou par thème :</small>
    <Button
      v-for="filter in tagFilters"
      @click="setCurrentTagFilter(filter)"
      :label="filter"
      :outlined="currentTagFilter !== filter"
      :severity="determineFAQTagSeverity(filter)"
      rounded
      size="small"
      :key="filter"
    />
  </div>
  <Accordion>
    <AccordionTab v-for="element in filteredElements" :key="element.title">
      <template #header>
        <div class="flex align-items-center justify-content-between gap-3 w-full">
          <h4 class="m-0">{{ element.title }}</h4>
          <Tag
            v-if="element.tag"
            :value="element.tag"
            :severity="determineFAQTagSeverity(element.tag)"
            class="text-xxs"
          />
        </div>
      </template>
      <div v-sane-html="element.answer"></div>
    </AccordionTab>
  </Accordion>
  <div class="flex flex-column align-items-center text-center gap-3 mt-4">
    <p class="m-0">Vous n'avez pas trouvé de réponses ? Vous pouvez :</p>
    <Button
      @click="emit('consultGuide')"
      label="Consulter le guide utilisateur"
      icon="pi pi-book"
      severity="info"
      size="small"
      rounded
    />
    <span>ou</span>
    <Button
      @click="emit('contactUs')"
      label="Nous contacter"
      icon="pi pi-comments"
      severity="help"
      size="small"
      rounded
    />
  </div>
</template>
