import { useCompanyStore } from '@/stores/company'
import {
  Address,
  CitiesSuggestion,
  Company,
  CompanyDetails,
  GeneralInformationsPayload,
  FREQUENCY,
  FREQUENCY_LABEL,
  Organization,
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
  PAYMENT_METHOD_PLUS,
  SOCIAL_ORGANIZATIONS,
  SocialOrganizationAPI,
} from './company.model'
import { dateFRtoBasic } from '@/utils'

export class Helper {
  static PRO_BTP_CODE = '77567053200404'

  static completeOrganization(
    organizationFromList: Organization,
    organizationFromAPI?: SocialOrganizationAPI,
  ): Organization | undefined {
    const organization = Object.assign(organizationFromList)
    if (organizationFromAPI) {
      organization.frequency = organizationFromAPI?.frequencePaiement
      organization.paymentMethod = organizationFromAPI?.methodePaiement

      if (organizationFromAPI.code === SOCIAL_ORGANIZATIONS.AGIRC_ARRCO) {
        if (organizationFromAPI?.autre === 'null') organization.other = null
        else organization.other = organizationFromAPI?.autre?.split(',')
      } else organization.other = organizationFromAPI.autre ? organizationFromAPI.autre : null

      if (organization.other) organization.showOther = true
    }

    return organization
  }

  static createPaymentMethodList(socialOrganization: SOCIAL_ORGANIZATIONS) {
    switch (socialOrganization) {
      case SOCIAL_ORGANIZATIONS.URSSAF:
      case SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS:
        return [
          { key: PAYMENT_METHOD.SEPA, value: PAYMENT_METHOD_LABEL.SEPA },
          { key: PAYMENT_METHOD_PLUS.CHEQUE_VIREMENT, value: PAYMENT_METHOD_LABEL.CHEQUE_VIREMENT },
        ]
      case SOCIAL_ORGANIZATIONS.DGFIP:
        return [{ key: PAYMENT_METHOD.SEPA, value: PAYMENT_METHOD_LABEL.SEPA }]
      default:
        return [
          { key: PAYMENT_METHOD.CHEQUE, value: PAYMENT_METHOD_LABEL.CHEQUE },
          { key: PAYMENT_METHOD.SEPA, value: PAYMENT_METHOD_LABEL.SEPA },
          { key: PAYMENT_METHOD.VIREMENT, value: PAYMENT_METHOD_LABEL.VIREMENT },
        ]
    }
  }

  static createFrequencyList() {
    return [
      { key: FREQUENCY.MENSUEL, value: FREQUENCY_LABEL.MENSUEL },
      { key: FREQUENCY.TRIMESTRIEL, value: FREQUENCY_LABEL.TRIMESTRIEL },
    ]
  }

  static transformSelectedSocialOrganizationForAPI(selected: Organization[]) {
    const organizations: SocialOrganizationAPI[] = []

    selected.forEach((organization) => {
      organizations.push({
        code: organization.code,
        frequencePaiement: organization.frequency,
        methodePaiement: organization.paymentMethod,
        autre: organization.other ? organization.other.toString() : null,
      })
    })

    return organizations
  }

  static storingDataFromAPI(company: CompanyDetails): Company {
    let dateCloture
    if (company.fiscalite.dateCloture.length > 5) {
      const formattedDate = new Date(dateFRtoBasic('01/' + company.fiscalite.dateCloture))
      dateCloture = new Date(formattedDate.getFullYear(), formattedDate.getMonth() + 1, 0)
    } else {
      dateCloture = new Date(dateFRtoBasic(company.fiscalite.dateCloture + '/' + new Date().getFullYear()))
    }
    const companyStore: Company = {
      id: company.informationsGenerales.id,
      generalInformations: {
        companyInfos: {
          siren: company.informationsGenerales.siret.substring(0, 9),
          nic: company.informationsGenerales.siret.substring(9, 14),
          companyName: company.informationsGenerales.raisonSociale,
          selectedApenCode: company.informationsGenerales.codeApen,
          selectedCollectiveConvention: company.informationsGenerales.ccn,
          selectedRegimeApplied: company.informationsGenerales.regime,
          selectedOpcoRef: company.informationsGenerales.codeOpco,
          payrollDeferral: company.informationsGenerales.decalagePaie,
        },
        locality: {
          zipCode: company.informationsGenerales.cp,
          selectedCity: null,
          city: company.informationsGenerales.localite,
          country: company.informationsGenerales.codePays === '' ? 'FRANCE' : company.informationsGenerales.codePays,
          isForeign: company.informationsGenerales.firmeEtrangere,
          address: this.determineAddressFromAPI(company.informationsGenerales),
        },
      },
      contact: {
        name: company.contact.nomPrenom,
        civility: company.contact.codeCivilite,
        email: company.contact.email,
        phoneNumber: company.contact.tel,
      },
      socialOrganizations: {
        selected: this.determineSelectedSocialOrganizationFromAPI(company.organimesSociaux.organismes),
        rib: { iban: company.organimesSociaux.rib.iban!, bic: company.organimesSociaux.rib.bic! },
      },
      taxation: {
        yearEndClosing: dateCloture,
        selectedTaxations: company.fiscalite.taxationsSelectionnees,
      },
      workAccidents: {
        riskCode: company.accidentTravail.codeRisque,
        effectiveAccidentRate: company.accidentTravail.tauxAt,
        additionalAccidentRate: company.accidentTravail.tauxAtSupplementaire,
      },
      notifications: company.notifications,
      status: company.status,
    }
    return companyStore
  }

  static determineSelectedSocialOrganizationFromAPI(organismes: SocialOrganizationAPI[]): Organization[] {
    const seletedSocialOrganizations: Organization[] = []

    const store = useCompanyStore()

    organismes.forEach((organisme) => {
      const selected = store.getSocialOrganizationAndCompleteIt(organisme.code as SOCIAL_ORGANIZATIONS, organisme)
      if (selected) seletedSocialOrganizations.push(selected)
    })

    return seletedSocialOrganizations
  }

  static determinedSelectedCityFromAPI(
    zipCode: string,
    localite: string,
    options: CitiesSuggestion[],
  ): CitiesSuggestion | null {
    return options.find((option) => option.codesPostal === zipCode && option.localite === localite) ?? null
  }

  static determineAddressFromAPI(companyInfo: GeneralInformationsPayload): Address {
    return {
      street: companyInfo.numeroVoie,
      addressSupplement: companyInfo.complementAdresse,
      namedPlace: companyInfo.complementAdresse2,
    }
  }
}
