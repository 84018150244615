export enum Router {
  HOME = '/',
  MY_ACCOUNT = '/mon-compte',
  MY_IDENTIFIERS = '/mes-identifiants',
  MY_COMPANIES = '/mes-entreprises',
  MY_SUBSCRIPTIONS = '/mes-abonnements',
  MY_BILLS = '/mes-factures',
  MY_SETTINGS = '/mes-parametres',
  COMPANY = '/entreprise',
  STEP_GENERAL_INFORMATIONS = '/informations-generales',
  STEP_CONTACT = '/contact',
  STEP_SOCIAL_ORGANIZATIONS = '/organismes-sociaux',
  STEP_TAXATION = '/fiscalite',
  STEP_WORK_ACCIDENT = '/accident-du-travail',
  EMPLOYEE = '/salarie',
  EMPLOYEES = '/salaries',
  CONTRACT = '/contrat',
  STEP_STATUS = '/status',
  STEP_CATEGORY = '/categorie',
  STEP_WORKING_HOURS_WAGES = '/temps-de-travail-et-salaire',
  STEP_COMPLEMENTARY_INFORMATIONS = '/informations-complementaires',
  COMPLEMENTARY_ORG_MEMBERSHIP = '/affiliation-organismes-complementaires',
  DASHBOARD = '/tableau-de-bord',
  DECLARATIONS = '/declarations',
  ORGANIZATIONS_PAYMENTS = '/paiements-organismes',
  SALARIES_PAID = '/salaires',
  DECLARATION_SUMMARY = '/declaration',
  INDIVIDUAL_DECLARATION = '/individuelle',
  REPORT_ENDING_CONTRACT = '/signalement-fin-de-contrat',
  LAST_MONTH_REVENUE = '/revenus-dernier-mois',
  REPORT_WORK_STOPPAGE = '/signalement-arret-de-travail',
  STEP_ADDITIONAL_INFORMATIONS = '/informations-supplementaires',
  REPORTS = '/signalements',
  REGULARIZATION = '/regularisation',
  SUBSCRIBE = '/abonnement',
  RECAPS = '/recapitulatifs',
  ADMIN = '/admin',
  COMPANIES = '/entreprises',
  USERS = '/utilisateurs',
  INVOICES = '/factures',
  SEARCH_DECLARATIONS = '/recherche-declarations',
  SEARCH_USER = '/recherche-utilisateur',
  SEARCH_COMPANY = '/recherche-entreprise',
  SEARCH_PAYMENT = '/recherche-paiement',
  SEARCH_INVOICE = '/recherche-facture',
  PAY_SLIPS = '/bulletins-de-paie',
  PAY_SLIPS_SUMMARY = '/resume',
  PAY_SLIP = '/bulletin-de-paie',
}
