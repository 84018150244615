import { defineStore } from 'pinia'

type State = {
  rowsPerPageOptions: number[]
  rowsPerPage: number
  isDarkMode: boolean
  fontSize: 'MINIMAL' | 'COMPACT' | 'NORMAL' | 'LARGE' | 'EXTRA'
  displayMode: 'COMPACT' | 'NORMAL' | 'LARGE'
  displayMenuInDeclaration: boolean
  sideBarIsMinify: boolean
}

export const useUtilsStore = defineStore('utilsStore', {
  state: (): State => ({
    rowsPerPageOptions: [5, 8, 10, 15, 20, 25, 30, 50, 75, 100],
    rowsPerPage: 10,
    isDarkMode: false,
    fontSize: 'NORMAL',
    displayMode: 'NORMAL',
    displayMenuInDeclaration: false,
    sideBarIsMinify: false,
  }),
  getters: {
    isCompactDisplayMode() {
      return () => this.displayMode === 'COMPACT'
    },
    isLargeDisplayMode() {
      return () => this.displayMode === 'LARGE'
    },
  },
  actions: {
    setRows(rowPerPage: number) {
      this.rowsPerPage = rowPerPage
    },
    setFontSize(fontSize: 'MINIMAL' | 'COMPACT' | 'NORMAL' | 'LARGE' | 'EXTRA') {
      this.fontSize = fontSize
    },
  },
  persist: true,
})
