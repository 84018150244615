<!-- eslint-disable camelcase -->
<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { computed, onMounted, ref, watch, onUnmounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useMainStore } from '@/stores/main'
import { usePrimeVue } from 'primevue/config'
import { useCompanyStore } from '@/stores/company'
import OnBoardingTour from './components/commons/OnBoardingTour.vue'
import AdminHeaderSection from '@/components/admin/AdminHeaderSection.vue'
import { useDeclarationStore } from './stores/declaration'
import { useUtilsStore } from './stores/utils'
import Sidebar from '@/components/commons/Sidebar.vue'
import HeaderSection from '@/components/commons/HeaderSection.vue'
import { useGtm } from '@gtm-support/vue-gtm'

const toast = useToast()
const authStore = useAuthStore()
const mainStore = useMainStore()
const companyStore = useCompanyStore()
const declarationStore = useDeclarationStore()
const utilsStore = useUtilsStore()
const primevue = usePrimeVue()
const gtm = useGtm()

const isMounted = ref(false)
const showSidebar = ref(true)
const loading = computed(() => mainStore.loading)
const isDarkMode = computed(() => utilsStore.isDarkMode)
const showWarningSessionExpired = computed(() => authStore.showWarningSessionExpired)
const displayOverlay = computed(() => declarationStore.individualDeclaration.focusPanel)
const hasSupportRights = computed(() => authStore.userInfos.hasSupportRights)
const sideBarIsMinify = computed(() => utilsStore.sideBarIsMinify)
const isAdminPage = computed(() => mainStore.isAdminPage)

const width = ref()

onMounted(() => {
  width.value = window.parent.innerWidth
  window.addEventListener('resize', myEventHandler)
  authStore.showWarningSessionExpired = false
  mainStore.formHaveBeenModified = false
  mainStore.hasRecentlySentDeclaration = false
  declarationStore.individualDeclaration.focusPanel = null
  mainStore.resetServerMessages()

  authStore.getCookieInfos().then(() => {
    const companyId = new URL(location.toString()).searchParams.get('eid')
    const signinParam = new URL(location.toString()).searchParams.get('signin')
    const signupParam = new URL(location.toString()).searchParams.get('signup')

    if (signinParam || signupParam) {
      gtm?.push({
        event: signupParam ? 'signup' : 'signin',
        user_id: authStore.userInfos.userId,
        user_email: authStore.userInfos.username,
        user_status: 'registered',
        env_channel: import.meta.env.MODE,
      })
    }

    if (companyId && mainStore.getCompanyById(Number(companyId))) {
      companyStore.company.id = Number(companyId)
    }

    if (isDarkMode.value) {
      primevue.changeTheme('lara-light-cyan', 'lara-dark-cyan', 'theme-link')
    } else {
      primevue.changeTheme('lara-dark-cyan', 'lara-light-cyan', 'theme-link')
    }

    if (!authStore.userInfos.hasSupportRights) {
      mainStore.displayOnBoardingTour().finally(() => {
        mainStore.loading = false
        isMounted.value = true
      })
    } else {
      mainStore.loading = false
      isMounted.value = true
    }
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', myEventHandler)
})

watch(
  mainStore.serverResponseStatut,
  () => {
    if (mainStore.serverResponseStatut.errorMessages) {
      for (const errorMessage of mainStore.serverResponseStatut.errorMessages) {
        if (!errorMessage.alreadyDisplayed) {
          toast.add({
            severity: errorMessage.blocking ? 'error' : 'warn',
            summary: errorMessage.blocking ? 'Erreur' : 'Avertissement',
            detail: errorMessage.value,
            life: 7000,
          })

          errorMessage.alreadyDisplayed = true
        }
      }
    }

    if (mainStore.serverResponseStatut.successMessage) {
      toast.add({
        severity: 'success',
        summary: 'Confirmation',
        detail: mainStore.serverResponseStatut.successMessage,
        life: 5000,
      })
      mainStore.serverResponseStatut.successMessage = ''
    }

    if (mainStore.serverResponseStatut.infoMessage) {
      toast.add({
        severity: 'info',
        summary: 'Information',
        detail: mainStore.serverResponseStatut.infoMessage,
        life: 5000,
      })
      mainStore.serverResponseStatut.infoMessage = ''
    }
  },
  { deep: true },
)

watch(isDarkMode, () => {
  if (isDarkMode.value) {
    primevue.changeTheme('lara-light-cyan', 'lara-dark-cyan', 'theme-link')
  } else {
    primevue.changeTheme('lara-dark-cyan', 'lara-light-cyan', 'theme-link')
  }
})

const deleteErrorIfNecessary = (toastMessage: any) => {
  const { message } = toastMessage
  if (message.severity === 'info') {
    mainStore.serverResponseStatut.infoMessage = ''
  } else if (message.severity === 'success') {
    mainStore.serverResponseStatut.successMessage = ''
  } else {
    const index = mainStore.serverResponseStatut.errorMessages.findIndex(
      (errorMessage) => !errorMessage.keepAfterDisplay && errorMessage.value === message.detail,
    )
    if (index !== -1) {
      mainStore.serverResponseStatut.errorMessages.splice(index, 1)
    }
  }
}

const logout = () => {
  authStore.logout()
}

const myEventHandler = (event: any) => {
  width.value = event.currentTarget.innerWidth
}

const releaseFocus = () => {
  declarationStore.individualDeclaration.focusPanel = null
}

// no other solution to prevent escape key to trigger false
watch(
  () => showSidebar.value,
  (show) => {
    if (!show) {
      showSidebar.value = true
    }
  },
)

watch(
  () => utilsStore.fontSize,
  (fontSize) => {
    if (fontSize === 'MINIMAL') {
      document.documentElement.style.fontSize = '12px'
    } else if (fontSize === 'COMPACT') {
      document.documentElement.style.fontSize = '14px'
    } else if (fontSize === 'LARGE') {
      document.documentElement.style.fontSize = '17px'
    } else if (fontSize === 'EXTRA') {
      document.documentElement.style.fontSize = '19px'
    } else {
      document.documentElement.style.fontSize = '16px'
    }
  },
  { immediate: true },
)

watch(
  () => utilsStore.displayMode,
  (displayMode) => {
    if (displayMode === 'COMPACT') {
      document.body.classList.toggle('compact-interface')
    } else if (displayMode === 'NORMAL') {
      document.body.classList.remove('compact-interface')
      document.body.classList.remove('big-interface')
    } else if (displayMode === 'LARGE') {
      document.body.classList.toggle('big-interface')
    }
  },
  { immediate: true },
)
</script>

<template>
  <div v-if="!loading && isAdminPage">
    <AdminHeaderSection />
    <div class="p-3 md:p-5 mt-6">
      <div v-if="loading || !isMounted" class="flex justify-content-center">
        <ProgressSpinner aria-label="Chargement de la page" />
      </div>
      <RouterView v-else />
    </div>
  </div>
  <div v-else-if="width > 1400" class="h-full padding-xxl">
    <Sidebar v-if="!loading && isMounted" v-model:visible="showSidebar" />
    <div
      class="p-3 md:p-5"
      :class="{
        'margin-with-sidebar-minify': sideBarIsMinify,
        'margin-with-sidebar': !sideBarIsMinify,
      }"
    >
      <div v-if="loading || !isMounted" class="flex justify-content-center">
        <ProgressSpinner aria-label="Chargement de la page" />
      </div>
      <RouterView v-else />
    </div>
  </div>
  <div v-else>
    <HeaderSection v-if="!loading && isMounted" />
    <div class="p-3 md:p-5 mt-8">
      <div v-if="loading || !isMounted" class="flex justify-content-center">
        <ProgressSpinner aria-label="Chargement de la page" />
      </div>
      <RouterView v-else />
    </div>
  </div>
  <Toast @close="deleteErrorIfNecessary" @lifeEnd="deleteErrorIfNecessary" />
  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-confirm-popup-content">
        <span class="p-confirm-popup-icon" :class="slotProps.message.icon" />
        <span v-sane-html="slotProps.message.message" class="p-confirm-popup-message"></span>
      </div>
    </template>
  </ConfirmPopup>
  <OnBoardingTour v-if="!loading && isMounted && !hasSupportRights" />
  <Dialog
    :visible="showWarningSessionExpired"
    modal
    blockScroll
    :draggable="false"
    :closable="false"
    header="Session expirée"
    class="darker-mask"
  >
    <p>Pour continuer, veuillez vous reconnecter</p>
    <template #footer>
      <Button @click="logout" label="Se connecter" icon="pi pi-sign-in" rounded class="p-button-sm-mobile" />
    </template>
  </Dialog>
  <div v-if="displayOverlay" @click="releaseFocus()" class="overlay-mask" />
</template>

<style scoped>
.overlay-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--maskbg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.margin-with-sidebar {
  margin-left: 16.75rem;
}

.margin-with-sidebar-minify {
  margin-left: 3.44rem;
}
</style>
