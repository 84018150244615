<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { router } from '@/routes'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { Router } from '@/models/router.model'
import { useCompanyStore } from '@/stores/company'
import { useEmployeeStore } from '@/stores/employee'
import { useMainStore } from '@/stores/main'
import SelectEmployeeModal from '@/components/contract/SelectEmployeeModal.vue'
import AssistanceModal from '@/components/commons/AssistanceModal.vue'
import ConfirmationChangingCompanyModal from '@/components/commons/ConfirmationChangingCompanyModal.vue'
import { Companies } from '@/models/main.model'
import CompaniesDropdown from './CompaniesDropdown.vue'
import ForceSelectionCompanyModal from './ForceSelectionCompanyModal.vue'
import { useUtilsStore } from '@/stores/utils'
import { COMPANY_NOTIFICATION_CODE } from '@/models/company.model'

const route = useRoute()
const mainStore = useMainStore()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const employeeStore = useEmployeeStore()
const utilsStore = useUtilsStore()

const showSelectEmployeeModal = ref(false)
const selectedCompany = ref(mainStore.selectedCompany)
const showAssistanceModal = ref(false)
const smallMenu = ref()
const notificationsOverlay = ref()
const previousSelectedCompany = ref<Companies | null>(null)
const showConfirmationChangingCompanyModal = ref(false)
const showForceSelectionCompanyModal = ref(false)

const smallMenuOptions = computed(() => [
  {
    separator: true,
  },
  { items: [{ label: 'Tableau de bord', route: Router.DASHBOARD }] },
  {
    label: 'Entreprise',
    items: [
      {
        label: 'Modifier les informations',
        icon: 'pi pi-pencil',
        route: Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS,
      },
      {
        label: 'Gérer les salariés',
        icon: 'pi pi-users',
        route: Router.DASHBOARD + Router.EMPLOYEES,
      },
      {
        label: 'Ajouter une entreprise',
        icon: 'pi pi-plus',
        command: () => {
          const selectedCompany = companyStore.company.id
          companyStore.$reset()
          companyStore.selectedCompanyBeforeCreation = selectedCompany
          router.push(Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS)
        },
      },
      {
        label: 'Ajouter un salarié',
        icon: 'pi pi-plus',
        route: Router.EMPLOYEE,
        command: () => {
          employeeStore.$reset()
        },
      },
      {
        label: 'Ajouter un contrat',
        icon: 'pi pi-plus',
        command: () => {
          if (route.path !== '/contrat') employeeStore.employee.id = null
          showSelectEmployeeModal.value = true
        },
      },
    ],
  },
  {
    label: 'Divers',
    items: [
      {
        label: utilsStore.isDarkMode ? 'Appliquer le thème clair' : 'Appliquer le thème sombre',
        icon: utilsStore.isDarkMode ? 'pi pi-sun' : 'pi pi-moon',
        command: () => {
          utilsStore.isDarkMode = !utilsStore.isDarkMode
        },
      },
      {
        label: "Besoin d'aide",
        icon: 'pi pi-question-circle',
        command: () => {
          showAssistanceModal.value = true
        },
      },
    ],
  },
  {
    label: 'Mon compte',
    items: [
      {
        label: 'Mes identifiants',
        icon: 'pi pi-user',
        route: Router.MY_ACCOUNT + Router.MY_IDENTIFIERS,
      },
      {
        label: 'Mes abonnements',
        icon: 'pi pi-euro',
        route: Router.MY_ACCOUNT + Router.MY_SUBSCRIPTIONS,
      },
      {
        label: 'Mes factures',
        icon: 'pi pi-file',
        route: Router.MY_ACCOUNT + Router.MY_BILLS,
      },
      {
        label: 'Mes entreprises',
        icon: 'pi pi-building',
        route: Router.MY_ACCOUNT + Router.MY_COMPANIES,
      },
      {
        label: 'Mes paramètres',
        icon: 'pi pi-cog',
        route: Router.MY_ACCOUNT + Router.MY_SETTINGS,
      },
      {
        label: 'Déconnexion',
        icon: 'pi pi-sign-out',
        command: () => {
          authStore.logout()
        },
      },
    ],
  },
])
const companyId = computed(() => companyStore.company.id)
const notifications = computed(() => companyStore.company.notifications)
const loading = computed(() => mainStore.loading)
const forceSelectionCompany = computed(() => mainStore.forceSelectionCompany)

onMounted(() => {
  if (companyId.value) {
    selectedCompany.value = mainStore.getCompanyById(companyId.value)
    companyStore.getCompanyDetails()
    employeeStore.getCompanyEmployees(companyId.value)
    previousSelectedCompany.value = selectedCompany.value
  } else {
    mainStore.selectedCompany = null
    mainStore.forceSelectionCompany = true
    showForceSelectionCompanyModal.value = true
  }
})

watch(
  () => companyStore.company.id,
  (companyId) => {
    if (companyId) {
      mainStore.resetServerMessages()
      selectedCompany.value = mainStore.getCompanyById(companyId)
      companyStore.getCompanyDetails()
      employeeStore.getCompanyEmployees(companyId)
    } else selectedCompany.value = null
  },
)

watch(selectedCompany, (selectedCompanyValue, oldSelectedCompany) => {
  if (selectedCompanyValue) {
    if (route.meta.mustReturnHomeIfCompanyChanged && selectedCompanyValue !== previousSelectedCompany.value) {
      previousSelectedCompany.value = oldSelectedCompany
      showConfirmationChangingCompanyModal.value = true
    } else {
      companyStore.company.id = selectedCompanyValue.id
      mainStore.selectedCompany = selectedCompanyValue
      mainStore.forceSelectionCompany = false
    }
  } else {
    selectedCompany.value = null
    companyStore.company.id = null
    companyStore.selectedCompanyBeforeCreation = null
  }
})

watch(forceSelectionCompany, () => {
  showForceSelectionCompanyModal.value = forceSelectionCompany.value
})

const showSmallMenu = (event: any) => {
  smallMenu.value.toggle(event)
}

const informUselessClick = (routerProps: any, event: Event) => {
  if (router.currentRoute.value.path === Router.DASHBOARD + Router.DECLARATIONS) {
    mainStore.serverResponseStatut.infoMessage = 'Vous visualisez déjà vos déclarations.'
  }
  routerProps.navigate(event)
}

const showNoficationsOverlay = (event: any) => {
  notificationsOverlay.value.toggle(event)
}
</script>

<template>
  <Toolbar class="shadow-5 p-3 border-none fixed w-full z-5 top-0 onboarding-step-1">
    <template #start>
      <Button
        @click="showSmallMenu"
        icon="pi pi-bars"
        rounded
        aria-haspopup="true"
        aria-controls="small-menu"
        class="flex lg:hidden"
      />
      <router-link to="/">
        <img src="@/assets/img/logo.png" class="header-logo hidden lg:flex" alt="Teledsn logo" />
      </router-link>
    </template>
    <template #center>
      <div class="flex md:justify-content-center gap-3 align-items-center">
        <router-link :to="Router.DASHBOARD + Router.DECLARATIONS" v-slot="routerProps" custom>
          <a
            @click="informUselessClick(routerProps, $event)"
            :href="routerProps.href"
            class="p-button p-button-contrast p-button-outlined p-button-sm no-underline p-button-rounded hidden lg:flex onboarding-step-1"
            aria-label="Visualiser vos déclarations pour l'entreprise sélectionnée"
            v-tippy="`Visualiser vos déclarations pour l'entreprise sélectionnée`"
          >
            <span>Tableau de bord</span>
          </a>
        </router-link>
        <CompaniesDropdown v-model:selectedCompany="selectedCompany" class="header-company-select onboarding-step-2" />
        <router-link :to="Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS" v-slot="routerProps" custom>
          <a
            @click="($event) => routerProps.navigate($event)"
            :href="routerProps.href"
            class="p-button p-button-contrast p-button-outlined p-button-sm no-underline p-button-rounded hidden md:flex"
            aria-label="Editer les informations de l'entreprise sélectionnée"
            v-tippy="`Editer les informations de l'entreprise sélectionnée`"
          >
            <span>Fiche entreprise</span>
          </a>
        </router-link>
      </div>
    </template>
    <template #end>
      <Button
        v-if="notifications && notifications.length > 0"
        @click="showNoficationsOverlay($event)"
        text
        rounded
        severity="secondary"
        class="mr-2 hidden md:flex pl-2"
        v-tippy="`Afficher les notifications liées à l'entreprise`"
      >
        <i v-badge.info="notifications.length" class="pi pi-bell text-2xl align-icon" />
      </Button>
      <ToggleButton
        v-else
        v-model="utilsStore.isDarkMode"
        onLabel=""
        offLabel=""
        class="mr-3 p-tooglebutton-rounded hidden xl:flex"
        v-tippy="`Changer le thème (clair ou sombre) de l'application`"
      >
        <template #icon>
          <i v-if="utilsStore.isDarkMode" class="pi pi-moon text-sm" />
          <i v-else class="pi pi-sun text-sm" />
        </template>
      </ToggleButton>
      <Button
        @click="showAssistanceModal = true"
        icon="pi pi-question-circle"
        severity="help"
        rounded
        text
        v-tippy="`Demande d'assistance au support`"
        class="mr-3 hidden md:flex onboarding-step-3"
      >
        <template #icon>
          <i class="pi pi-question-circle text-xl" />
        </template>
      </Button>
      <Button
        @click="router.push(Router.MY_ACCOUNT + Router.MY_COMPANIES)"
        severity="secondary"
        icon="pi pi-cog"
        rounded
        text
        class="hidden md:flex onboarding-step-4"
        v-tippy="`Accéder aux informations du compte`"
        data-testid="header-account-btn"
      >
        <template #icon>
          <i class="pi pi-cog text-xl" />
        </template>
      </Button>
    </template>
  </Toolbar>
  <Menu ref="smallMenu" :model="smallMenuOptions" popup id="small-menu">
    <template #start>
      <div class="flex justify-content-center">
        <img src="@/assets/img/logo.png" class="header-logo" alt="Teledsn logo" />
      </div>
    </template>
    <template #submenuheader="{ item }">
      <span class="text-primary font-bold">{{ item.label }}</span>
    </template>
    <template #item="{ item, props }">
      <router-link v-if="item.route" :to="item.route" class="flex align-items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
      </router-link>
      <a v-else class="flex align-items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
      </a>
    </template>
  </Menu>
  <OverlayPanel ref="notificationsOverlay" class="overlay-min-width">
    <div class="flex flex-column gap-3">
      <u class="w-full text-lg">Notifications :</u>
      <div v-for="(notification, index) in notifications" :key="notification.code" class="w-full">
        <InlineMessage
          v-if="notification.code === COMPANY_NOTIFICATION_CODE.CONFIGURATION_SHEET_MAJ"
          :severity="!companyStore.complementaryOrganismsAvailable() ? 'warn' : 'info'"
        >
          Votre fiche paramétrage a récemment été mise à jour, pensez à vérifier les affiliations de vos salariés.
          <p v-if="!companyStore.complementaryOrganismsAvailable()" class="mb-0">
            Vous devriez certainement ajouter l'organisme <b>« ORGANISME COMPLÉMENTAIRE »</b> dans la fiche entreprise
            (étape 3).
          </p>
        </InlineMessage>
        <p v-else>
          {{ notification.libelle }}
        </p>
        <hr v-if="index !== notifications.length - 1" />
      </div>
    </div>
  </OverlayPanel>
  <SelectEmployeeModal v-if="!loading" v-model:visible="showSelectEmployeeModal" />
  <AssistanceModal v-if="!loading" v-model:visible="showAssistanceModal" />
  <ForceSelectionCompanyModal
    v-if="!loading"
    :visible="showForceSelectionCompanyModal"
    v-model:selectedCompany="selectedCompany"
  />
  <ConfirmationChangingCompanyModal
    v-if="!loading"
    v-model:visible="showConfirmationChangingCompanyModal"
    v-model:newSelectedCompany="selectedCompany"
    v-model:oldSelectedCompany="previousSelectedCompany"
  />
</template>

<style scoped>
.header-logo {
  height: 50px;
}

.align-icon {
  top: 3px;
}
</style>
